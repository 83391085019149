import * as React from 'react';
import { forwardRef, memo, Ref } from 'react';
import type { SvgProps } from 'react-native-svg';
import Svg, { Defs, LinearGradient, Path, Stop } from 'react-native-svg';
const ShieldCheckGradientIcon = (
	props: SvgProps & { size?: number },
	ref: Ref<Svg>,
) => (
	<Svg
		width={props.size || 24}
		height={props.size || 24}
		viewBox="0 0 24 24"
		ref={ref}
		{...props}
	>
		<Defs>
			<LinearGradient
				id="shieldCheckGradient"
				x1="0%"
				y1="0%"
				x2="100%"
				y2="100%"
			>
				<Stop offset="0%" stopColor="#FFFEC2" />
				<Stop offset="100%" stopColor="#BAECFF" />
			</LinearGradient>
		</Defs>
		<Path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M3.378 5.082C3 5.62 3 7.22 3 10.417v1.574c0 5.638 4.239 8.375 6.899 9.536.721.315 1.082.473 2.101.473 1.02 0 1.38-.158 2.101-.473C16.761 20.365 21 17.63 21 11.991v-1.574c0-3.198 0-4.797-.378-5.335-.377-.537-1.88-1.052-4.887-2.081l-.573-.196C13.595 2.268 12.812 2 12 2s-1.595.268-3.162.805L8.265 3C5.258 4.03 3.755 4.545 3.378 5.082M15.06 10.5a.75.75 0 0 0-1.12-.999l-3.011 3.374-.87-.974a.75.75 0 0 0-1.118 1l1.428 1.6a.75.75 0 0 0 1.119 0z"
			fill="url(#shieldCheckGradient)"
		/>
	</Svg>
);

const ForwardRef = forwardRef(ShieldCheckGradientIcon);
const Memo = memo(ForwardRef);
export default Memo;
