import { SCREEN_MAX_WIDTH } from '@wearemojo/ui-constants';
import { ReactNode } from 'react';
import { Falsy, StyleProp, View as RNView, ViewStyle } from 'react-native';
import {
	NativeSafeAreaViewProps,
	SafeAreaView as RNSafeAreaView,
} from 'react-native-safe-area-context';
import { createStyleSheet, useStyles } from 'react-native-unistyles';

import useUIContext from './hooks/useUIContext';
import ScrollContainer, {
	Props as ScrollContainerProps,
} from './ScrollContainer';
import TabBar from './TabBar';

type Props = {
	children: ReactNode;
	style?: StyleProp<ViewStyle> | Falsy;
	innerStyle?: StyleProp<ViewStyle> | Falsy;
};

type PaddingProps = {
	noTopGutter?: boolean;
	noBottomGutter?: boolean;
};

type SafeAreaProps = {
	edges?: NativeSafeAreaViewProps['edges'];
};

const useRootStyles = (style: StyleProp<ViewStyle> | Falsy) => {
	const { styles } = useStyles(stylesheet);
	return [styles.root, style];
};

const RootView = ({ children, style, innerStyle }: Props) => {
	const rootStyles = useRootStyles(style);
	const { styles } = useStyles(stylesheet);
	return (
		<RNView style={rootStyles}>
			<RNView style={[styles.inner, innerStyle]}>{children}</RNView>
		</RNView>
	);
};

const SafeAreaView = ({
	children,
	style,
	innerStyle,
	edges,
}: Props & SafeAreaProps) => {
	const rootStyles = useRootStyles(style);
	const { styles } = useStyles(stylesheet);
	return (
		<RNView style={rootStyles}>
			<RNSafeAreaView style={[styles.inner, innerStyle]} edges={edges}>
				{children}
			</RNSafeAreaView>
		</RNView>
	);
};

const ScrollView = ({
	children,
	style,
	innerStyle,
	scrollDirection = 'vertical',
	scrollViewRef,
}: Props & ScrollContainerProps) => {
	const rootStyles = useRootStyles(style);
	const { styles } = useStyles(stylesheet);
	return (
		<ScrollContainer
			style={rootStyles}
			scrollDirection={scrollDirection}
			scrollViewRef={scrollViewRef}
		>
			<RNView style={[styles.inner, innerStyle]}>{children}</RNView>
		</ScrollContainer>
	);
};

const SafeScrollAreaView = ({
	children,
	style,
	innerStyle,
	scrollDirection = 'vertical',
	edges,
}: Props & SafeAreaProps & ScrollContainerProps) => {
	const rootStyles = useRootStyles(style);
	const { styles } = useStyles(stylesheet);
	return (
		<ScrollContainer style={rootStyles} scrollDirection={scrollDirection}>
			<RNSafeAreaView style={[styles.inner, innerStyle]} edges={edges}>
				{children}
			</RNSafeAreaView>
		</ScrollContainer>
	);
};

const NavSpacingView = ({ children, style }: Props) => {
	const { isDesktop, isWeb } = useUIContext();
	const { styles } = useStyles(stylesheet, { isDesktop, isWeb });
	return (
		<SafeAreaView edges={['bottom']} style={[styles.navSpacing, style]}>
			{children}
		</SafeAreaView>
	);
};

const Padding = ({
	children,
	style,
	noTopGutter,
	noBottomGutter,
	...props
}: Props & PaddingProps) => {
	const { styles } = useStyles(stylesheet, { noBottomGutter, noTopGutter });
	return (
		<RootView style={[styles.padding, style]} {...props}>
			{children}
		</RootView>
	);
};

const EscapePadding = ({
	children,
	style,
	noTopGutter,
	noBottomGutter,
	...props
}: Props & PaddingProps) => {
	const { styles } = useStyles(stylesheet, { noBottomGutter, noTopGutter });
	return (
		<RootView style={[styles.escapePadding, style]} {...props}>
			{children}
		</RootView>
	);
};

const Page = ({ children, style, ...props }: Props & PaddingProps) => {
	const { styles } = useStyles(stylesheet);
	return (
		<Padding style={[styles.page, style]} {...props}>
			{children}
		</Padding>
	);
};

RootView.WithSafeArea = SafeAreaView;
RootView.WithScroll = ScrollView;
RootView.WithSafeScrollArea = SafeScrollAreaView;
RootView.WithNavSpacing = NavSpacingView;

// @TODO: think of better names and break out
RootView.Page = Page;
RootView.Padding = Padding;
RootView.EscapePadding = EscapePadding;

const stylesheet = createStyleSheet(({ spacing }) => ({
	root: {
		flex: 1,
	},
	inner: {
		flexGrow: 1,
		flexShrink: 0,
	},
	navSpacing: {
		borderBottomColor: 'transparent',
		borderBottomWidth: TabBar.Height,

		variants: {
			isDesktop: {
				true: {
					borderBottomWidth: TabBar.HeightDesktop,
				},
			},
			isWeb: {
				true: {
					borderBottomWidth: 0,
					// Add extra padding to account for the tab bar on web
					paddingBottom: spacing.xl5 + spacing.xl5,
				},
			},
		},
	},
	padding: {
		// The inverse should exist on `escapePadding`
		padding: spacing.xl,
		variants: {
			noTopGutter: {
				true: {
					paddingTop: spacing.none,
				},
			},
			noBottomGutter: {
				true: {
					paddingBottom: spacing.none,
				},
			},
		},
	},
	escapePadding: {
		// The inverse should exist on `padding`
		margin: -spacing.xl,
		variants: {
			noTopGutter: {
				true: {
					marginTop: spacing.none,
				},
			},
			noBottomGutter: {
				true: {
					marginBottom: spacing.none,
				},
			},
		},
	},
	page: {
		flex: 1,
		minWidth: 280,
		maxWidth: SCREEN_MAX_WIDTH,
		alignSelf: 'center',
		width: '100%',
	},
}));

export default RootView;
