import { PortableTextComponentProps } from '@portabletext/react';
import { ProgressTrailType } from '@wearemojo/sanity-schema';

import CMSContent from '../CMSContent';
import ProgressTrail from '../ProgressTrail';
import CMSBlock from './CMSBlock';

type CMSProgressTrailProps = PortableTextComponentProps<ProgressTrailType>;
const CMSProgressTrail = ({
	value: { stepsCompleted, items = [] },
}: CMSProgressTrailProps) => (
	<CMSBlock textAlign="auto">
		<ProgressTrail stepsCompleted={stepsCompleted}>
			{items.map(({ title, inlineContent }, index) => (
				<ProgressTrail.Item title={title} key={index}>
					{inlineContent && <CMSContent value={inlineContent} />}
				</ProgressTrail.Item>
			))}
		</ProgressTrail>
	</CMSBlock>
);

export default CMSProgressTrail;
