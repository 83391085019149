import { createContext } from 'react';

type ModalNavContextState = {
	pinControls?: boolean;
	usePinControlsV2?: boolean;
};

const ModalNavContext = createContext<ModalNavContextState>({});

export default ModalNavContext;
