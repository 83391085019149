import { UITheme } from '@wearemojo/ui-constants';
import { ReactNode, useContext } from 'react';

import UIContext from './UIContext';

type Props = {
	children: ReactNode;
	force?: UITheme;
};

const InvertTheme = ({ children, force }: Props) => {
	const context = useContext(UIContext);
	const theme = force ?? UITheme.dark;
	return (
		<UIContext.Provider
			value={{
				...context,
				theme,
			}}
		>
			{children}
		</UIContext.Provider>
	);
};

export default InvertTheme;
