import {
	Audio as ExpoAudio,
	AVPlaybackStatus,
	ResizeMode,
	Video as ExpoVideo,
} from 'expo-av';
import { createRef, useCallback, useEffect, useState } from 'react';
import { Image, Platform, Pressable, StyleSheet, View } from 'react-native';
import { Path, Svg } from 'react-native-svg';

import { PlaybackProps, SharedVideoProps } from '../Video';

export type UriVideoConfig = {
	uri: string;
};

type Props = UriVideoConfig & SharedVideoProps & PlaybackProps;

const useInjectVideoScriptsWeb = () => {
	useEffect(() => {
		if (Platform.OS !== 'web') return;
		const scriptElement = document.createElement('script');
		scriptElement.src = 'http://cdn.dashjs.org/latest/dash.all.min.js';
		document.head!.appendChild(scriptElement);
	});
};

const UriVideo = ({
	uri,
	posterUri,
	playbackStatus,
	setPlaybackStatus,
	autoplay,
}: Props) => {
	useInjectVideoScriptsWeb();

	const videoRef = createRef<ExpoVideo>();
	const onPlaybackStatusUpdate = useCallback(
		(avPlaybackStatus: AVPlaybackStatus) => {
			/*
				AVPlaybackStatus includes millisecond precision, we don't currently
				need this and it dramatically increases re-renders
			*/
			const REPORTING_THRESHOLD_IN_SECONDS = 10;
			if (
				avPlaybackStatus.isLoaded &&
				// playing status changed
				(avPlaybackStatus.isPlaying !== playbackStatus.isPlaying ||
					// or progress changed over reporting threshold
					(avPlaybackStatus.positionMillis &&
						Math.abs(
							avPlaybackStatus.positionMillis / 1000 -
								(playbackStatus.position || 0),
						) > REPORTING_THRESHOLD_IN_SECONDS))
			) {
				const position = avPlaybackStatus.positionMillis / 1000;
				const duration =
					avPlaybackStatus.durationMillis != null
						? avPlaybackStatus.durationMillis / 1000
						: undefined;
				setPlaybackStatus({
					isPlaying: avPlaybackStatus.isPlaying,
					duration,
					position,
				});
			}
		},
		[playbackStatus, setPlaybackStatus],
	);

	const [showPoster, setShowPoster] = useState(!!posterUri);
	useEffect(() => {
		if (showPoster && playbackStatus.isPlaying === true) {
			setShowPoster(false);
		}
	}, [playbackStatus, showPoster]);

	const onPressPlay = async () => {
		await ExpoAudio.setAudioModeAsync({ playsInSilentModeIOS: true });
		const avPlaybackStatus = await videoRef.current?.getStatusAsync();

		if (
			avPlaybackStatus &&
			avPlaybackStatus.isLoaded &&
			avPlaybackStatus.durationMillis &&
			avPlaybackStatus.positionMillis &&
			avPlaybackStatus.durationMillis - avPlaybackStatus.positionMillis <= 1000
		) {
			// If video finished, and we press play again, then play from start
			return videoRef.current?.playFromPositionAsync(0);
		}

		return videoRef.current?.playAsync();
	};

	const onLoad = async () => {
		if (autoplay) {
			await videoRef.current?.setIsMutedAsync(true);
			onPressPlay();
		}
	};

	const source = { uri };
	const poster =
		posterUri && showPoster ? (
			<Pressable onPress={onPressPlay} style={styles.poster}>
				<Image
					source={{ uri: posterUri }}
					resizeMode="contain"
					style={StyleSheet.absoluteFill}
				/>
			</Pressable>
		) : null;

	return (
		<View style={StyleSheet.absoluteFill}>
			<ExpoVideo
				ref={videoRef}
				style={StyleSheet.absoluteFill}
				source={source}
				usePoster={false}
				useNativeControls
				resizeMode={ResizeMode.CONTAIN}
				onPlaybackStatusUpdate={onPlaybackStatusUpdate}
				onLoad={onLoad}
			/>
			{poster}
			{!playbackStatus.isPlaying && <PlayButton onPressPlay={onPressPlay} />}
		</View>
	);
};

const PlayButton = ({ onPressPlay }: { onPressPlay: () => void }) => (
	<View style={styles.playContainer}>
		<Pressable
			onPress={onPressPlay}
			style={styles.playIcon}
			testID="PlayButton"
		>
			<Svg viewBox="0 0 48 48">
				<Path
					d="M14 15.06V33a2 2 0 0 0 2.93 1.77l17.09-8.97a2 2 0 0 0 0-3.54L16.93 13.3A2 2 0 0 0 14 15.06z"
					fill="#fff"
				/>
			</Svg>
		</Pressable>
	</View>
);

const styles = StyleSheet.create({
	poster: {
		...StyleSheet.absoluteFillObject,
		backgroundColor: '#000',
	},
	playContainer: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		height: '25%',
		aspectRatio: 1,
	},
	playIcon: {
		top: '-50%',
		left: '-50%',
		aspectRatio: 1,
		backgroundColor: 'rgba(0, 0, 0, 0.3);',
		borderRadius: 9999,
	},
});

export default UriVideo;
