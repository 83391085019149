import { ClipPath, Defs, G, Path, Svg } from 'react-native-svg';

import { MojoIcon } from '../utils/types';

export default ({ color, size = 24 }: MojoIcon) => (
	<Svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill="none">
		<G
			clipPath="url(#clip0_430_810)"
			stroke={color}
			strokeWidth={2}
			strokeLinecap="round"
			strokeLinejoin="round"
		>
			<Path d="M12.496 21.5V6.698l2.249-2.252L21.244 3l2.25 2.252V17.02l-2.25 2.252-8.748 2.23zM19.676 9.702l-3.27.833M19.676 13.965l-3.27.832" />
			<Path d="M12.5 21.5V6.698l-2.25-2.252L3.75 3 1.5 5.252V17.02l2.25 2.252 8.75 2.23zM5.316 9.702l3.27.833M5.316 13.965l3.27.832" />
		</G>
		<Defs>
			<ClipPath id="clip0_430_810">
				<Path fill={color} transform="translate(.5)" d="M0 0H24V24H0z" />
			</ClipPath>
		</Defs>
	</Svg>
);
