import { PortableTextComponentProps } from '@portabletext/react';
import type { ResearchCarouselType } from '@wearemojo/sanity-schema';

import ResearchCarousel from '../ResearchCarousel';
import CMSBlock from './CMSBlock';

type CMSResearchCarouselProps =
	PortableTextComponentProps<ResearchCarouselType>;
const CMSResearchCarousel = ({ value }: CMSResearchCarouselProps) => {
	const { title, content } = value;
	return (
		<CMSBlock>
			<ResearchCarousel title={title} content={content} />
		</CMSBlock>
	);
};

export default CMSResearchCarousel;
