import ArrowRightIcon from '@wearemojo/icons/Outline/Arrows/ArrowRightIcon';
import { Spacing } from '@wearemojo/ui-constants';
import { StyleSheet } from 'react-native';

import Button from '../Button';
import PopupInfo from '../PopupInfo';
import Stack from '../Stack';
import TextAction from '../TextAction';

type Props = {
	title: string;
	skipCta: string;
	onSkipPress: () => void;
	exitCta: string;
	onExitPress: () => void;
	cancelCta?: string;
	onCancelPress: () => void;
};

const RegisterPrefaceExitView = ({
	title,
	skipCta,
	onSkipPress,
	exitCta,
	onExitPress,
	cancelCta,
	onCancelPress,
}: Props) => {
	return (
		<PopupInfo title={title} onClosePress={onCancelPress}>
			<Stack itemFlex spacing={Spacing.regular} style={styles.buttonContainer}>
				<Button
					title={skipCta}
					icon={ArrowRightIcon}
					type="secondary"
					onPress={onSkipPress}
					iconPosition="right"
				/>
				<Button
					title={exitCta}
					icon={ArrowRightIcon}
					type="primary"
					onPress={onExitPress}
					iconPosition="right"
				/>
				{cancelCta && (
					<TextAction
						title={cancelCta}
						onPress={onCancelPress}
						position="center"
					/>
				)}
			</Stack>
		</PopupInfo>
	);
};

const styles = StyleSheet.create({
	root: {
		justifyContent: 'center',
		alignItems: 'center',
		flex: 1,
	},
	buttonContainer: {
		marginTop: Spacing.large,
	},
});

export default RegisterPrefaceExitView;
