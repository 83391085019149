import { ComponentType } from 'react';
import { createStyleSheet, useStyles } from 'react-native-unistyles';

import BackgroundView from '../BackgroundView';
import Button from '../Button';
import CMSContent from '../CMSContent';
import Stack from '../Stack';
import Text from '../Text';
import TextAction from '../TextAction';
import { BlockContent, MojoIcon } from '../utils/types';

type Props = {
	icon?: ComponentType<MojoIcon>;
	title: string;
	content: BlockContent;
	ctaText: string;
	onCtaPress: () => void;
	secondaryCtaText?: string;
	onSecondaryCtaPress?: () => void;
	textActionCta?: string;
	onTextActionCtaPress?: () => void;
};

const AbstractModalView = ({
	icon: Icon,
	title,
	content,
	ctaText,
	secondaryCtaText,
	textActionCta,
	onCtaPress,
	onSecondaryCtaPress,
	onTextActionCtaPress,
}: Props) => {
	const {
		styles,
		theme: { spacing, colors, iconSize },
	} = useStyles(stylesheet);
	return (
		<Stack spacing={spacing.xl}>
			<Stack spacing={spacing.md}>
				{Icon && (
					<BackgroundView
						background="background_level_2"
						style={styles.iconContainer}
					>
						<Icon color={colors.fill_highlight} size={iconSize.lg} />
					</BackgroundView>
				)}
				<Text.CMSContent
					value={title}
					variant="heading_sm"
					style={styles.textCenter}
				/>
			</Stack>
			<Stack spacing={spacing.sm}>
				<Text.TextContextProvider style={styles.textCenter}>
					<CMSContent value={content} />
				</Text.TextContextProvider>
			</Stack>
			<Stack spacing={spacing.xs}>
				<Button onPress={onCtaPress} title={ctaText} />
				{secondaryCtaText != null && onSecondaryCtaPress != null && (
					<Button
						onPress={onSecondaryCtaPress}
						type="secondary"
						title={secondaryCtaText}
					/>
				)}
				{textActionCta != null && onTextActionCtaPress != null && (
					<TextAction onPress={onTextActionCtaPress} title={textActionCta} />
				)}
			</Stack>
		</Stack>
	);
};

const stylesheet = createStyleSheet(() => ({
	iconContainer: {
		width: 64,
		height: 64,
		borderRadius: 32,
		justifyContent: 'center',
		alignItems: 'center',
		alignSelf: 'center',
	},
	textCenter: {
		textAlign: 'center',
	},
}));

export default AbstractModalView;
