import { borderRadius, Spacing } from '@wearemojo/ui-constants';
import { ReactNode } from 'react';
import { StyleSheet, View } from 'react-native';
import CheckCircleIcon from 'react-native-heroicons/solid/CheckCircleIcon';

import Accordion from './Accordion';
import BackgroundView from './BackgroundView';
import useUITheme from './hooks/useUITheme';
import Stack from './Stack';
import Text from './Text';

type Props = {
	initialExpanded?: boolean;
	pointsPosition: 'above_video' | 'below_video';
	video: ReactNode;
	points: string[];
};

const VideoKeyPoints = ({
	initialExpanded,
	pointsPosition,
	video,
	points = [],
}: Props) => {
	const fill = useUITheme().fill_highlight;
	const pointsEl = (
		<Accordion compact initialExpanded={initialExpanded ? [0] : undefined}>
			<Accordion.Item title="Key points in video">
				<Stack style={styles.points} spacing={Spacing.large}>
					{points.map((point, i) => (
						<View style={styles.point} key={i}>
							<CheckCircleIcon fill={fill} style={styles.pointIcon} />
							<Text>{point}</Text>
						</View>
					))}
				</Stack>
			</Accordion.Item>
		</Accordion>
	);
	return (
		<BackgroundView background="background_secondary" style={styles.root}>
			{pointsPosition === 'above_video' && pointsEl}
			<View style={styles.video}>{video}</View>
			{pointsPosition === 'below_video' && pointsEl}
		</BackgroundView>
	);
};

const styles = StyleSheet.create({
	root: {
		borderRadius,
		overflow: 'hidden',
	},
	video: {
		backgroundColor: 'black',
	},
	points: {},
	point: {
		paddingLeft: Spacing.extraLarge,
	},
	pointIcon: {
		position: 'absolute',
		top: 0,
		left: 0,
	},
});

export default VideoKeyPoints;
