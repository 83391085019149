import { PortableTextComponentProps } from '@portabletext/react';
import { ExpertQuoteType } from '@wearemojo/sanity-schema';

import CMSContent from '../CMSContent';
import ExpertQuote from '../ExpertQuote';
import { useCMSGlobalContext } from '../utils/cms';
import CMSBlock from './CMSBlock';

type CMSExpertQuoteProps = PortableTextComponentProps<ExpertQuoteType>;
const CMSExpertQuote = ({
	value: { author, byline, content, authorImage },
}: CMSExpertQuoteProps) => {
	const { imgAttr } = useCMSGlobalContext();
	let uri;
	if (authorImage) {
		uri = imgAttr(authorImage).uri;
	}
	return (
		<CMSBlock textAlign="auto">
			<ExpertQuote author={author} byline={byline} authorImage={{ uri }}>
				<CMSContent value={content} />
			</ExpertQuote>
		</CMSBlock>
	);
};

export default CMSExpertQuote;
