import { InlineContentType } from '@wearemojo/sanity-schema';
import Text from '@wearemojo/ui-components/src/Text';
import { borderRadius, Spacing } from '@wearemojo/ui-constants';
import React from 'react';
import { Image, StyleSheet, View } from 'react-native';

export type TileWithExplanationProps = {
	image: {
		uri: string;
	};
	imageAlt: string;
	title?: string;
	content: InlineContentType;
};

const TileWithExplanation = ({
	image,
	imageAlt,
	title,
	content,
}: TileWithExplanationProps) => (
	<View style={styles.container}>
		<Image source={image} style={styles.image} accessibilityLabel={imageAlt} />
		<View style={styles.textContainer}>
			{title && <Text weight="bold">{title}</Text>}
			<Text.CMSContent value={content} />
		</View>
	</View>
);

const styles = StyleSheet.create({
	container: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		maxWidth: 640,
	},
	image: {
		width: 100,
		height: 100,
		borderRadius,
		marginRight: Spacing.large,
	},
	textContainer: {
		flex: 1,
	},
});

export default TileWithExplanation;
