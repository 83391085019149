import { BlockContentType } from '@wearemojo/sanity-schema';
import { Spacing } from '@wearemojo/ui-constants';

import Button from '../../Button';
import CMSContent from '../../CMSContent';
import PopupInfo from '../../PopupInfo';
import Stack from '../../Stack';

type Props = {
	content: BlockContentType;
	primaryCtaText?: string;
	secondaryCtaText?: string;
	onPrimaryCtaPress?: () => void;
	onSecondaryCtaPress?: () => void;
	onClosePress?: () => void;
};

export const ExercisePelvicFloorConfirmationModalView = ({
	content,
	primaryCtaText,
	secondaryCtaText,
	onPrimaryCtaPress,
	onSecondaryCtaPress,
	onClosePress,
}: Props) => {
	if (onPrimaryCtaPress && !primaryCtaText) {
		throw new Error('Primary CTA text is required');
	}

	if (onSecondaryCtaPress && !secondaryCtaText) {
		throw new Error('Secondary CTA text is required');
	}

	return (
		<PopupInfo onClosePress={onClosePress}>
			<Stack spacing={Spacing.dsDeviation__20}>
				<CMSContent value={content} />

				<Stack spacing={Spacing.regular}>
					{onPrimaryCtaPress && (
						<Button title={primaryCtaText} onPress={onPrimaryCtaPress} />
					)}
					{onSecondaryCtaPress && (
						<Button
							title={secondaryCtaText}
							onPress={onSecondaryCtaPress}
							type="secondary"
						/>
					)}
				</Stack>
			</Stack>
		</PopupInfo>
	);
};

export default ExercisePelvicFloorConfirmationModalView;
