import { IconType } from '@wearemojo/sanity-schema';
import {
	borderRadius,
	Spacing,
	themeColors,
	UITheme,
} from '@wearemojo/ui-constants';
import { Pressable, StyleSheet } from 'react-native';

import useUITheme from './hooks/useUITheme';
import Text from './Text';
import { cmsIcons } from './utils/cms';
import { PressableCallback } from './utils/types';

type SplitControlPillProps = {
	icon?: IconType;
	label: string;
	selected?: boolean;
	onPress?: () => void;
};

const SplitControlPill = ({
	icon,
	label,
	onPress,
	selected,
}: SplitControlPillProps) => {
	const uiTheme = useUITheme();

	const Icon = icon && cmsIcons[icon];
	const iconColor = selected
		? uiTheme.content_on_fill
		: uiTheme.content_primary;

	return (
		<Pressable
			onPress={onPress}
			style={(state) => {
				const { hovered, pressed } = state as PressableCallback;
				return [
					styles.root,
					selected && styles.rootSelected,
					(hovered || pressed) && styles.rootFocused,
				];
			}}
		>
			{Icon && <Icon size={24} stroke={iconColor} />}
			<Text
				numberOfLines={1}
				variant="label_sm"
				style={styles.text}
				themeColor={selected ? 'content_on_fill' : undefined}
			>
				{label}
			</Text>
		</Pressable>
	);
};

const styles = StyleSheet.create({
	root: {
		height: 32,
		borderRadius,
		backgroundColor: themeColors[UITheme.dark].background_secondary,
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'row',
		paddingHorizontal: Spacing.regular,
	},
	rootSelected: {
		backgroundColor: themeColors[UITheme.dark].fill_highlight,
	},
	rootFocused: {
		opacity: 0.8,
	},
	text: {
		marginLeft: Spacing.extraSmall,
	},
});

export default SplitControlPill;
