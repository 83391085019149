import { BrandColor } from '@wearemojo/ui-constants';
import { ComponentType, ReactNode } from 'react';
import { View } from 'react-native';
import { createStyleSheet, useStyles } from 'react-native-unistyles';

import Text from '../Text';
import { MojoIcon } from '../utils/types';

export type Props = {
	title?: string;
	icon: ComponentType<MojoIcon>;
	type: 'green' | 'purple' | 'blue' | 'yellow' | 'orange';
	children?: ReactNode;
};

const CalloutCard = ({ title, type, icon: Icon, children }: Props) => {
	const { styles } = useStyles(stylesheet, { type });

	return (
		<View style={styles.container}>
			<View style={styles.root}>
				<Icon color={styles.icon.color} />
				<View style={styles.contentContainer}>
					{!!title && <Text variant="heading_xs">{title}</Text>}
					{children}
				</View>
			</View>
		</View>
	);
};

const stylesheet = createStyleSheet(({ spacing, radius, colors }) => ({
	container: {
		paddingTop: 1,
		borderRadius: radius.md,
		variants: {
			type: {
				green: {
					backgroundColor: BrandColor.primary_green,
				},
				purple: {
					backgroundColor: BrandColor.primary_purple,
				},
				blue: {
					backgroundColor: BrandColor.primary_blue,
				},
				orange: {
					backgroundColor: BrandColor.primary_orange,
				},
				yellow: {
					backgroundColor: BrandColor.primary_yellow,
				},
			},
		},
	},
	root: {
		padding: spacing.lg,
		borderRadius: radius.md,
		gap: spacing.sm,
		backgroundColor: `${colors.background_primary}e6`,
	},
	icon: {
		variants: {
			type: {
				green: {
					color: BrandColor.primary_green,
				},
				purple: {
					color: BrandColor.primary_purple,
				},
				blue: {
					color: BrandColor.primary_blue,
				},
				orange: {
					color: BrandColor.primary_orange,
				},
				yellow: {
					color: BrandColor.primary_yellow,
				},
			},
		},
	},
	contentContainer: {
		gap: spacing.xs,
	},
}));

export default CalloutCard;
