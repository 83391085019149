import { useState } from 'react';

import Button from './Button';
import {
	InteractionEvent,
	useInteractionEvents,
} from './utils/interactionEvents';

type Props = {
	type: 'primary' | 'secondary' | 'warning';
	title: string;
	titlePressed: string;

	trackingKey: string;
	trackingParams?: Record<string, unknown>;
};

const TrackingButton = ({
	type,
	title,
	titlePressed,
	trackingKey: key,
	trackingParams: params,
}: Props) => {
	const { dispatch } = useInteractionEvents();
	const [pressed, setPressed] = useState(false);

	const onPress = () => {
		dispatch(InteractionEvent.tracking_button_pressed, { ...params, key });
		setPressed(true);
	};

	return (
		<Button
			type={type}
			size="inline_start"
			title={pressed ? titlePressed : title}
			disabled={pressed}
			onPress={onPress}
		/>
	);
};

export default TrackingButton;
