import { Spacing } from '@wearemojo/ui-constants';
import {
	createContext,
	ReactNode,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { StyleSheet, View } from 'react-native';

import Text from './Text';
import { TextVariantish } from './utils/types';

type Props = {
	children: ReactNode;
	type?: ItemType;
};

type ItemType = 'bullet' | 'number';

type ItemProps = {
	children: ReactNode;
	variant?: TextVariantish;
};

type ContextType = {
	type: ItemType;
	getNum: () => number;
	releaseNum: () => void;
};

export const ListContext = createContext<ContextType>({
	type: 'bullet',
	getNum: () => 0,
	releaseNum: () => 0,
});

export const List = ({ children, type = 'bullet' }: Props) => {
	const count = useRef(1);
	const getNum = useMemo(() => {
		return () => count.current++;
	}, []);
	const releaseNum = useCallback(() => {
		count.current--;
	}, []);
	const context = useMemo(
		() => ({ type, getNum, releaseNum }),
		[type, getNum, releaseNum],
	);
	return (
		<View style={styles.root}>
			<ListContext.Provider value={context}>{children}</ListContext.Provider>
		</View>
	);
};

export const ListItem = ({ children, variant }: ItemProps) => {
	const { type, getNum, releaseNum } = useContext(ListContext);
	const [marker, setMarker] = useState(' •');
	useEffect(() => {
		if (type === 'number') {
			setMarker(`${getNum()}.`);
			return () => releaseNum();
		}
	}, [type, getNum, releaseNum]);
	return (
		<View style={styles.item}>
			<View style={styles.itemBullet}>
				<Text variant={variant}>{marker}</Text>
			</View>
			<View style={styles.itemContent}>
				<Text variant={variant}>{children}</Text>
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	root: {
		paddingLeft: Spacing.large,
		textAlign: 'left',
	},
	itemBullet: {
		position: 'relative',
		minWidth: 22,
		left: -Spacing.large,
		marginRight: -Spacing.large,
		paddingRight: Spacing.small,
	},
	item: {
		flexDirection: 'row',
		marginBottom: Spacing.extraSmall,
	},
	itemContent: {
		flexShrink: 1,
	},
});
