import { Fragment, ReactNode } from 'react';
import { View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { createStyleSheet, useStyles } from 'react-native-unistyles';

import Belt from './Belt';
import ContainedAction from './ContainedAction';

export type Props = {
	onPressClose?: () => void;
	onPressSettings?: () => void;
	HelpButton?: ReactNode;
	transparent?: boolean;
	reverseButtons?: boolean;
	showBackIcon?: boolean;
	actions?: ReactNode[];
};

const FullScreenModalHeader = ({
	onPressClose,
	onPressSettings,
	HelpButton,
	transparent = false,
	reverseButtons,
	showBackIcon,
	actions,
}: Props) => {
	const {
		styles,
		theme: { spacing },
	} = useStyles(stylesheet, { transparent });

	const safeAreaInsets = useSafeAreaInsets();
	const actionsPaddingTop =
		safeAreaInsets.top < spacing.lg
			? spacing.lg
			: Math.min(spacing.xl4, safeAreaInsets.top);

	return (
		<View style={styles.container}>
			<Belt>
				<View
					style={[
						styles.actions,
						{ paddingTop: actionsPaddingTop },
						reverseButtons && styles.actionsReverse,
					]}
				>
					{HelpButton}
					{onPressSettings && (
						<ContainedAction type="settings" onPress={onPressSettings} />
					)}
					{actions?.map((action, index) => (
						<Fragment key={index}>{action}</Fragment>
					))}
					{onPressClose && (
						<ContainedAction
							type={showBackIcon ? 'back' : 'close'}
							onPress={onPressClose}
						/>
					)}
				</View>
			</Belt>
		</View>
	);
};

const stylesheet = createStyleSheet(({ spacing, colors }) => ({
	container: {
		variants: {
			transparent: {
				true: {
					backgroundColor: 'transparent',
				},
				false: {
					backgroundColor: colors.background_primary,
				},
			},
		},
	},
	actions: {
		width: '100%',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'flex-end',
		gap: spacing.sm,
		paddingHorizontal: spacing.xs,
	},
	actionsReverse: {
		justifyContent: 'space-between',
		flexDirection: 'row-reverse',
	},
}));

export default FullScreenModalHeader;
