import {
	borderRadius,
	Spacing,
	themeColors,
	UITheme,
} from '@wearemojo/ui-constants';
import { useState } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import ExclamationIcon from 'react-native-heroicons/solid/ExclamationIcon';

import Stack from './Stack';
import Text from './Text';
import { monospaceFont } from './utils/typography';

type Props = {
	message: string;
	label?: string;
	fillParent?: boolean;
	level?: 'error' | 'warning';
};

const TRUNCATE_LENGTH = 90;
const TRUNCATE_ELIPSIS = '...';

const ErrorBox = ({
	message,
	label,
	fillParent = false,
	level = 'error',
}: Props) => {
	const [expanded, setExpanded] = useState(false);

	const shouldTruncate =
		message.length > TRUNCATE_LENGTH + TRUNCATE_ELIPSIS.length;
	const messageText =
		!expanded && shouldTruncate ? message.slice(0, TRUNCATE_LENGTH) : message;
	return (
		<View
			style={[
				styles.root,
				fillParent && styles.rootFillParent,
				level === 'warning' && styles.rootWarning,
			]}
		>
			<Stack spacing={Spacing.extraSmall}>
				<View style={[shouldTruncate && styles.titleWithExpandTrigger]}>
					<Stack
						direction="row"
						spacing={Spacing.extraSmall}
						itemFlex={[false, true]}
					>
						<ExclamationIcon
							fill={themeColors[UITheme.dark].content_on_light}
							size={16}
							style={styles.icon}
						/>
						<Text variant="label_sm" style={styles.text} weight="bold">
							{label ?? (level === 'warning' ? 'Warning' : 'Error')}
						</Text>
					</Stack>
					{shouldTruncate && (
						<View style={styles.expandTrigger}>
							<Text
								variant="label_sm"
								style={[styles.text, styles.expandText]}
								onPress={() => setExpanded(!expanded)}
							>
								{expanded ? '-' : '+'}
							</Text>
						</View>
					)}
				</View>
				<ScrollView>
					<View>
						<Text style={[styles.text, styles.messageText]}>
							{messageText}
							{shouldTruncate && !expanded && TRUNCATE_ELIPSIS}
						</Text>
					</View>
				</ScrollView>
			</Stack>
		</View>
	);
};

const styles = StyleSheet.create({
	root: {
		backgroundColor: 'pink',
		textAlign: 'left',
		borderRadius,
		paddingVertical: Spacing.small,
		paddingHorizontal: Spacing.regular,
		margin: Spacing.extraSmall,
		opacity: 0.9,
		minHeight: 56, // Ensures first line of error message is always visible
	},
	rootWarning: {
		backgroundColor: 'rgba(240, 240, 240, 0.9)',
	},
	rootFillParent: {
		...StyleSheet.absoluteFillObject,
	},
	text: {
		color: themeColors[UITheme.dark].content_on_light,
		borderRadius,
	},
	messageText: {
		fontFamily: monospaceFont,
		fontSize: 10,
		lineHeight: 16,
		color: '#444',
	},
	expandTrigger: {
		position: 'absolute',
		top: 0,
		right: 0,
	},
	expandText: {
		margin: -Spacing.small,
		padding: Spacing.small,
	},
	titleWithExpandTrigger: {
		paddingRight: 25,
	},
	icon: {
		marginTop: 2,
		opacity: 0.5,
	},
});

export default ErrorBox;
