import { BrandColor } from '@wearemojo/ui-constants';
import { View } from 'react-native';
import { createStyleSheet, useStyles } from 'react-native-unistyles';

import Stack from './Stack';
import Text from './Text';

type Props = {
	items: { title: string }[];
};

const NumberedList = ({ items }: Props) => {
	const { styles, theme } = useStyles(stylesheet);

	return (
		<Stack spacing={theme.spacing.xs}>
			{items.map(({ title }, i) => (
				<View key={i} style={styles.itemContainer}>
					<View style={styles.numberBox}>
						<Text variant="body_sm">{i + 1}</Text>
					</View>
					<Text
						style={styles.title}
						variant="body_lg"
						themeColor="content_neutral"
					>
						{title}
					</Text>
				</View>
			))}
		</Stack>
	);
};

const stylesheet = createStyleSheet(({ spacing }) => ({
	itemContainer: {
		flexDirection: 'row',
		paddingVertical: spacing.xs,
		alignItems: 'flex-start',
		gap: spacing.sm,
	},
	numberBox: {
		height: 24,
		width: 24,
		borderRadius: 12,
		backgroundColor: BrandColor.neutral_500,
		alignItems: 'center',
		justifyContent: 'center',
		marginTop: spacing.xs2,
	},
	title: {
		flex: 1,
	},
}));

export default NumberedList;
