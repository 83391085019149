import { Path, Svg } from 'react-native-svg';

import { MojoIcon } from '../utils/types';

export default ({ color, size = 24 }: MojoIcon) => (
	<Svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill="none">
		<Path
			d="M1.5 15.2507L4.25246 13H5.02704L7 15.2507V19.5L5.02704 21.6722H4.25246L1.5 19.4216V15.2507Z"
			stroke={color}
			strokeWidth="2.32951"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<Path
			d="M22.5 19.4212L19.7475 21.6719L18.973 21.6719L17 19.5L17 15.2503L18.973 12.9996L19.7475 12.9996L22.5 15.2503L22.5 19.4212Z"
			stroke={color}
			strokeWidth="2.32951"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<Path
			d="M1.5 15L2.5 3.90841L8.90841 2H15.0916L21.5 3.90841L22.5 15.388"
			stroke={color}
			strokeWidth="2.32951"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</Svg>
);
