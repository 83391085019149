import { borderRadius } from '@wearemojo/ui-constants';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { StyleSheet, useWindowDimensions, ViewStyle } from 'react-native';

import {
	InteractionEvent,
	useInteractionEvents,
	VideoInteractionEvent,
} from './utils/interactionEvents';
import { ScreenEvent, useScreenEvent } from './utils/screenEvents';
import CloudflareVideo, {
	CloudflareVideoConfig,
} from './video/CloudflareVideo';
import UriVideo, { UriVideoConfig } from './video/UriVideo';
import ViewRatio from './ViewRatio';

export type SharedVideoProps = {
	posterUri?: string;
	autoplay?: boolean;
};

export type VideoConfig = SharedVideoProps &
	(
		| ({ type: 'uri' } & UriVideoConfig)
		| ({ type: 'cloudflare' } & CloudflareVideoConfig)
	);

export type PlaybackStatus = {
	isPlaying: boolean;

	// only supported by CloudflareVideo on web at the moment
	position?: number;
	duration?: number;
};

export type PlaybackProps = {
	playbackStatus: PlaybackStatus;
	setPlaybackStatus: Dispatch<SetStateAction<PlaybackStatus>>;
};

const DEFAULT_ASPECT_RATIO = 1.78; // 16 / 9 = 1.777...

type Props = {
	config: VideoConfig;
	aspectRatio?: number;
	maxHeightPercentage?: number;
	useNativePlayer?: boolean;
	eventFields: VideoInteractionEvent;
};

const Video = ({
	config,
	aspectRatio: _aspectRatio,
	maxHeightPercentage,
	useNativePlayer,
	eventFields,
	...sharedProps
}: Props) => {
	const aspectRatio = _aspectRatio ?? DEFAULT_ASPECT_RATIO;

	const { dispatch } = useInteractionEvents();

	const [playbackStatus, setPlaybackStatus] = useState<PlaybackStatus>({
		isPlaying: false,
	});
	const [isUnloading, setIsUnloading] = useState(false);

	const playerProps = {
		playbackStatus,
		setPlaybackStatus,
		...sharedProps,
	};

	// @TODO: CloudflareVideo lacks a pause interface, workaround to toggle render
	useScreenEvent(ScreenEvent.focus, () => setIsUnloading(false));
	useScreenEvent(ScreenEvent.blur, () => setIsUnloading(true));

	const { isPlaying, position, duration } = playbackStatus;
	const isPlayingRef = useRef(false);
	useEffect(() => {
		if (isPlaying && !isPlayingRef.current) {
			isPlayingRef.current = true;
			dispatch(InteractionEvent.video_started, eventFields);
		}
	}, [dispatch, eventFields, isPlaying]);

	const videoProgress = duration ? (position ?? 0) / duration : undefined;

	const isMajorityComplete = isPlaying && videoProgress && videoProgress >= 0.5;
	const isMajorityCompleteRef = useRef(false);
	useEffect(() => {
		if (isMajorityComplete && !isMajorityCompleteRef.current) {
			isMajorityCompleteRef.current = true;
			dispatch(InteractionEvent.video_majority_completed, eventFields);
		}
	}, [dispatch, eventFields, isMajorityComplete]);

	// Consider completed if video played to at least 95%
	const isCompleted = videoProgress && videoProgress >= 0.95;
	const isCompletedRef = useRef(false);
	useEffect(() => {
		if (isCompleted && !isCompletedRef.current) {
			isCompletedRef.current = true;
			dispatch(InteractionEvent.video_completed, eventFields);
		}
	}, [dispatch, eventFields, isCompleted]);

	let player;
	switch (config.type) {
		case 'cloudflare':
			player = (
				<CloudflareVideo
					{...config}
					{...playerProps}
					useNativePlayer={useNativePlayer}
				/>
			);
			break;
		case 'uri':
			player = <UriVideo {...config} {...playerProps} />;
			break;
		default:
			throw new Error(`Unknown video type (${JSON.stringify(config)})`);
	}

	const { height } = useWindowDimensions();
	const forceHeight = maxHeightPercentage
		? height * maxHeightPercentage
		: undefined;

	return (
		<ViewRatio
			style={[
				styles.root,
				forceHeight ? getForceHeightStyle(forceHeight, aspectRatio) : undefined,
			]}
			aspectRatio={aspectRatio}
		>
			{isUnloading ? null : player}
		</ViewRatio>
	);
};

const getForceHeightStyle = (
	forceHeight: number,
	aspectRatio: number,
): ViewStyle => ({
	width: forceHeight * aspectRatio,
	alignSelf: 'center',
});

const styles = StyleSheet.create({
	root: {
		width: '100%',
		maxWidth: '100%',
		position: 'relative',
		borderRadius,
		overflow: 'hidden',
		backgroundColor: 'black',
	},
});

export default Video;
