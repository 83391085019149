import { ComponentType, Suspense } from 'react';

import LoadingIndicator from '../LoadingIndicator';

type LazyScreenLoaderProps = {
	children: React.ReactNode;
	name: string;
};

export const LazyScreenLoader = ({ children, name }: LazyScreenLoaderProps) => {
	return (
		<Suspense fallback={<LoadingIndicator label={`${name} suspense`} />}>
			{children}
		</Suspense>
	);
};

export function withSuspense<P extends Object>(Component: ComponentType<P>) {
	function WithSuspenseProvider(props: P) {
		// @ts-ignore
		const name = props?.route?.name || '';
		return (
			<LazyScreenLoader name={name}>
				<Component {...props} />
			</LazyScreenLoader>
		);
	}

	return WithSuspenseProvider;
}
