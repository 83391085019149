import { useEffect, useMemo } from 'react';
import { EasingFunction, ImageStyle, TextStyle, ViewStyle } from 'react-native';
import {
	AnimatedStyleProp,
	AnimatedTransform,
	Easing,
	ReduceMotion,
	useAnimatedStyle,
	useSharedValue,
	withSequence,
	withSpring,
	withTiming,
} from 'react-native-reanimated';

enum SequenceAnimationProp {
	// Add more as needed (requires implementation)
	opacity = 'opacity',
	scale = 'scale',
	translateX = 'translateX',
	translateY = 'translateY',
}
export type SequenceAnimationConfig = Partial<
	Record<SequenceAnimationProp, SequenceAnimationKeyFrame[]>
>;
export type SequenceAnimationKeyFrame = {
	frame: number;
	value: number;
	easing?: EasingFunction;
};
type SequenceAnimationOptions = { fps?: number };
const TRANSFORM_PROPS = [
	// Add more as needed (requires implementation)
	'scale',
	'translateX',
	'translateY',
];

/**
	Wrapper around react-native-reanimated to simplifiy sequence animations

	Example usage:

		const { animatedStyles } = useSequenceAnimation({
			scale: [
				{ frame: 10, value: 0 },
				{ frame: 15, value: 1.2 },
				{ frame: 24, value: 1 },
			],
			opacity: [
				{ frame: 11, value: 0 },
				{ frame: 24, value: 1 },
			],
		});

		return (
			<Animated.View style={[styles.container, animatedStyles]}>
				...
			</Animated.View>
		);
 */
const useSequenceAnimation = (
	config: SequenceAnimationConfig,
	{ fps }: SequenceAnimationOptions = {},
) => {
	const opacity = useSharedValue(config.opacity?.[0]?.value ?? 1);
	const scale = useSharedValue(config.scale?.[0]?.value ?? 1);
	const translateX = useSharedValue(config.translateX?.[0]?.value ?? 0);
	const translateY = useSharedValue(config.translateY?.[0]?.value ?? 0);
	const sharedValues = useMemo(
		() => ({ opacity, scale, translateX, translateY }),
		[opacity, scale, translateX, translateY],
	);

	// @ts-ignore @TODO understand why type is incorrect and fix it
	const animatedStyles = useAnimatedStyle(() => {
		const styles: AnimatedStyleProp<ViewStyle | TextStyle | ImageStyle> = {};
		const transform: AnimatedTransform = [];

		Object.values(SequenceAnimationProp).forEach((prop) => {
			const animation = config[prop];
			if (!animation) {
				return;
			}
			if (TRANSFORM_PROPS.includes(prop)) {
				// @ts-ignore @TODO fix incorrect type with withSpring
				transform.push({ [prop]: withSpring(sharedValues[prop].value) });
				return;
			}
			// @ts-ignore @TODO fix incorrect type with withSpring
			styles[prop] = withSpring(sharedValues[prop].value);
		});
		return {
			...styles,
			transform,
		};
	}, [config, sharedValues]);

	const staticStyles = useMemo(() => {
		const styles: AnimatedStyleProp<ViewStyle | TextStyle | ImageStyle> = {};
		const transform: AnimatedTransform = [];

		Object.values(SequenceAnimationProp).forEach((prop) => {
			const sequenceProp = config[prop];
			const lastFrame = sequenceProp?.[sequenceProp.length - 1];
			if (!lastFrame) {
				return;
			}
			if (TRANSFORM_PROPS.includes(prop)) {
				// @ts-ignore @TODO fix incorrect type
				transform.push({ [prop]: lastFrame.value });
			} else {
				// @ts-ignore @TODO fix incorrect type
				styles[prop] = lastFrame.value;
			}
		});

		return {
			...styles,
			transform,
		};
	}, [config]);

	useEffect(() => {
		Object.values(SequenceAnimationProp).forEach((prop) => {
			const animation = config[prop];
			if (animation) {
				sharedValues[prop].value = withSequenceAnimation(animation, { fps });
			}
		});
	}, [config, fps, sharedValues]);

	/**
	 * Potential future improvements:
	 * 	-	Config option to not autostart on render
	 * 	- Ability to stop/start/restart sequence
	 * 	- Events/callbacks for start/end
	 *  - Autostart animation only once screen is visible (useScreenEvent?)
	 */
	return { animatedStyles, staticStyles };
};

const withSequenceAnimation = (
	keyFrames: SequenceAnimationKeyFrame[],
	{ fps }: SequenceAnimationOptions = {},
) => {
	return withSequence(
		// @ts-ignore incomplete/incorrect type in library
		...createAnimationSequence(keyFrames, { fps }),
	);
};

const createAnimationSequence = (
	keyFrames: SequenceAnimationKeyFrame[],
	{ fps }: SequenceAnimationOptions = {},
) => {
	return keyFrames.map((current, keyFrameIndex) => {
		const prev: { frame: number } = keyFrames[keyFrameIndex - 1] ?? {
			frame: 0,
		};
		return withTiming(current.value, {
			duration: frameCountToMs(current.frame - prev.frame, fps),
			easing: current.easing ?? Easing.ease,
			reduceMotion: ReduceMotion.Never,
		});
	});
};

const frameCountToMs = (frameCount: number, fps: number = 30) => {
	return (1000 / fps) * frameCount;
};

export default useSequenceAnimation;
