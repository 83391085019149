import { Spacing } from '@wearemojo/ui-constants';
import { StyleSheet, View } from 'react-native';

import Button from '../Button';
import RootView from '../RootView';
import Stack from '../Stack';
import Text from '../Text';

type Props = {
	onPressRetry: () => void;
};

const FailedToConnectView = ({ onPressRetry }: Props) => (
	<RootView>
		<View style={styles.inner}>
			<Stack spacing={Spacing.extraLarge}>
				<Text style={styles.text} variant="heading_md">
					Failed to connect
				</Text>
				<Text style={styles.text}>
					Sorry you appear to be offline{'\n'}
					Mojo requires an active connection to function
				</Text>
				{onPressRetry && (
					<Button title="Retry" size="inline_center" onPress={onPressRetry} />
				)}
			</Stack>
		</View>
	</RootView>
);

const styles = StyleSheet.create({
	inner: {
		flex: 1,
		justifyContent: 'center',
		padding: Spacing.regular,
	},
	text: {
		textAlign: 'center',
	},
});

export default FailedToConnectView;
