import { SCREEN_MAX_WIDTH_SM } from '@wearemojo/ui-constants';
import { PropsWithChildren } from 'react';
import { Platform, ScrollView, useWindowDimensions, View } from 'react-native';
import { createStyleSheet, useStyles } from 'react-native-unistyles';

import BackgroundView from '../BackgroundView';
import Button from '../Button';
import ContainedAction from '../ContainedAction';
import Stack from '../Stack';
import Text from '../Text';
import TextAction from '../TextAction';

type Props = {
	manage: boolean;
	title: string;
	acceptCta: string;
	onAcceptPress: () => void;
	rejectCta: string;
	onRejectPress: () => void;
	saveCta: string;
	onSavePress: () => void;
	manageCta?: string;
	onManagePress: () => void;
	onClosePress: () => void;
};

const CookieConsentView = ({
	manage,
	title,
	acceptCta,
	onAcceptPress,
	rejectCta,
	onRejectPress,
	saveCta,
	onSavePress,
	manageCta,
	onManagePress,
	onClosePress,
	children,
}: PropsWithChildren<Props>) => {
	const {
		styles,
		theme: { spacing },
	} = useStyles(stylesheet);
	const { height } = useWindowDimensions();
	return (
		<BackgroundView background="background_modal" style={styles.root}>
			<BackgroundView background="background_secondary" style={styles.dialog}>
				<Stack spacing={spacing.lg}>
					<View style={styles.header}>
						<Text variant="heading_xs" style={styles.headerTitleText}>
							{title}
						</Text>
						<View style={styles.containedActionContainer}>
							<ContainedAction
								type="close"
								onPress={onClosePress}
								position="center"
							/>
						</View>
					</View>
					<ScrollView style={{ maxHeight: height * 0.6 }}>
						<Stack spacing={spacing.lg}>{children}</Stack>
					</ScrollView>
					<Stack spacing={spacing.xs2} style={styles.buttonContainer}>
						<Stack
							direction={!manage ? 'row' : 'column'}
							itemFlex
							spacing={spacing.sm}
						>
							<Button
								title={acceptCta}
								type="primary"
								onPress={onAcceptPress}
							/>
							{!manage ? (
								<Button
									title={rejectCta}
									type="secondary"
									onPress={onRejectPress}
								/>
							) : (
								<Button
									title={saveCta}
									type="secondary"
									onPress={onSavePress}
								/>
							)}
						</Stack>
						{!manage && manageCta ? (
							<TextAction
								title={manageCta}
								onPress={onManagePress}
								position="center"
							/>
						) : undefined}
					</Stack>
				</Stack>
			</BackgroundView>
		</BackgroundView>
	);
};

const stylesheet = createStyleSheet(({ spacing, colors, radius }) => ({
	root: {
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: colors.background_modal,
		flex: 1,
	},
	dialog: {
		maxWidth: Platform.select({
			web: SCREEN_MAX_WIDTH_SM,
			default: undefined,
		}),
		borderRadius: radius.sm,
		padding: spacing.md,
		margin: spacing.md,
		gap: spacing.md,
	},
	header: {
		flexDirection: 'row',
		alignItems: 'center',
	},
	headerTitleText: {
		flex: 1,
		marginTop: 2,
	},
	containedActionContainer: {
		marginTop: 2,
	},
	buttonContainer: {
		marginTop: spacing.md,
	},
}));

export default CookieConsentView;
