import { Stream, StreamPlayerApi } from '@cloudflare/stream-react';
import { themeColors, UITheme } from '@wearemojo/ui-constants';
import { useRef } from 'react';

import { PlaybackProps, SharedVideoProps } from '../Video';
import UriVideo from './UriVideo';

export type CloudflareVideoConfig = {
	videoId: string;
};

type Props = CloudflareVideoConfig &
	SharedVideoProps &
	PlaybackProps & {
		useNativePlayer?: boolean;
	};

const CloudflareVideo = ({
	videoId,
	playbackStatus,
	setPlaybackStatus,
	posterUri,
	autoplay,
	useNativePlayer = false,
}: Props) => {
	const useIframe = !useNativePlayer;
	const streamRef = useRef<StreamPlayerApi>();

	const onPlay = () => {
		setPlaybackStatus({
			...playbackStatus,
			isPlaying: true,
		});
	};

	const onPause = () => {
		setPlaybackStatus({
			...playbackStatus,
			isPlaying: false,
		});
	};

	const onTimeUpdate = () => {
		if (streamRef.current) {
			setPlaybackStatus({
				...playbackStatus,
				position: streamRef.current.currentTime,
				duration: streamRef.current.duration,
			});
		}
	};

	return useIframe ? (
		<Stream
			streamRef={streamRef}
			src={videoId}
			poster={posterUri}
			controls={true}
			letterboxColor={themeColors[UITheme.dark].background_always_dark}
			onPlay={onPlay}
			onPause={onPause}
			onDurationChange={onTimeUpdate}
			onTimeUpdate={onTimeUpdate}
			autoplay={autoplay}
			muted={autoplay}
		/>
	) : (
		<UriVideo
			uri={`https://cloudflarestream.com/${videoId}/manifest/video.mpd`}
			playbackStatus={playbackStatus}
			setPlaybackStatus={setPlaybackStatus}
			posterUri={posterUri}
			autoplay={autoplay}
		/>
	);
};

export default CloudflareVideo;
