import { PortableTextComponentProps } from '@portabletext/react';
import { InfoCardBlockType } from '@wearemojo/sanity-schema';

import InfoCard from '../cards/InfoCard';
import CMSContent from '../CMSContent';
import CMSBlock from './CMSBlock';

type CMSInfoCardBlockProps = PortableTextComponentProps<InfoCardBlockType>;

const InfoCardBlock = ({
	value: { title, content },
}: CMSInfoCardBlockProps) => {
	return (
		<CMSBlock textAlign="auto">
			<InfoCard title={title}>
				<CMSContent value={content} />
			</InfoCard>
		</CMSBlock>
	);
};

export default InfoCardBlock;
