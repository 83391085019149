import { createContext, useContext } from 'react';

type ApiState = {
	notificationPermission?: {
		state?: { granted: boolean };
		request?: () => void;
	};
	preferDirectVideo?: {
		isOn?: boolean;
		isLoading: boolean;
	};
};

export const createApiStateContext = (): ApiState => {
	return {};
};

export const ApiStateContext = createContext(createApiStateContext());

export const useApiState = () => {
	return useContext(ApiStateContext);
};
