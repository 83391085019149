import { Spacing } from '@wearemojo/ui-constants';
import { Children, ReactNode } from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import Block from './Block';

const Stack = ({
	children,
	spacing,
	style,
	direction = 'column',
	itemFlex = false,
}: {
	children: ReactNode;
	spacing?: Spacing;
	style?: StyleProp<ViewStyle>;
	direction?: 'column' | 'row';
	itemFlex?: boolean | Array<boolean>;
}) => {
	if (spacing !== undefined && !Spacing[spacing]) {
		throw new Error(
			`Invalid spacing: ${spacing}, valid values: [${Object.keys(Spacing)}]`,
		);
	}
	const nonNullChildren = Children.toArray(children).filter(Boolean);
	const totalChildren = Children.toArray(nonNullChildren).length;
	const wrappedChildren = Children.map(nonNullChildren, (child, index) => {
		const isLastChild = index + 1 === totalChildren;
		const shouldFlex = Array.isArray(itemFlex) ? itemFlex[index] : itemFlex;
		return (
			<Block
				spacing={isLastChild ? undefined : spacing}
				direction={direction}
				style={[styles.item, shouldFlex && styles.itemFlex]}
			>
				{child}
			</Block>
		);
	});
	return (
		<View style={[styles.root, direction === 'row' && styles.rootRow, style]}>
			{wrappedChildren}
		</View>
	);
};

const styles = StyleSheet.create({
	root: {
		width: '100%',
	},
	rootRow: {
		flexDirection: 'row',
	},
	item: {},
	itemFlex: {
		flex: 1,
	},
});

export default Stack;
