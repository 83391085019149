import LockKeyholeMinimalisticIcon from '@wearemojo/icons/Outline/Security/LockKeyholeMinimalisticIcon';
import {
	borderRadius,
	BrandColor,
	iconSize,
	Spacing,
} from '@wearemojo/ui-constants';
import { Fragment } from 'react';
import {
	Image as RNImage,
	ImageSourcePropType,
	Pressable,
	StyleSheet,
	View,
} from 'react-native';

import BackgroundView from '../BackgroundView';
import Caption from '../Caption';
import WaveformIcon from '../icons/WaveformIcon';
import Image from '../Image';
import Text from '../Text';
import { LinkProviderType } from '../utils/links';
import { InlineContent } from '../utils/types';

export enum TileCardVariant {
	text = 'text',
	image = 'image',
	iconImage = 'iconImage',
	exercise = 'exercise',
	meditation = 'meditation',
	therapy = 'therapy',
}

export type Props = {
	title?: string;
	subtitle?: InlineContent;
	image?: ImageSourcePropType;
	imageFormat?: string;
	variant?: TileCardVariant;
	linkProvider?: LinkProviderType;
	onPress?: () => void;
	caption?: string;
	locked?: boolean;
	tileSize?: number;
	tileAspectRatio?: number;
};

const DEFAULT_TILE_SIZE = 150;
const DEFAULT_ASPECT_RATIO = 1;

// TODO fully convert to unistyles once we have new designs

const TileCard = ({
	title,
	subtitle,
	variant = TileCardVariant.text,
	image,
	imageFormat,
	linkProvider,
	onPress,
	caption,
	locked,
	tileSize = DEFAULT_TILE_SIZE,
	tileAspectRatio = DEFAULT_ASPECT_RATIO,
}: Props) => {
	const isImageTile = variant === TileCardVariant.image && image;
	const isIconImageTile = variant === TileCardVariant.iconImage;
	const label = isIconImageTile ? undefined : isImageTile ? title : subtitle;

	const isAudioTile = [
		TileCardVariant.meditation,
		TileCardVariant.therapy,
	].includes(variant);
	const LinkProvider = linkProvider || Fragment;

	return (
		<LinkProvider>
			<Pressable
				style={[
					styles.root,
					isIconImageTile && styles.rootIconImage,
					{ width: tileSize },
				]}
				onPress={onPress}
			>
				<BackgroundView
					background={
						variant === TileCardVariant.exercise
							? 'fill_highlight'
							: variant === TileCardVariant.meditation
								? 'background_neutral'
								: 'background_secondary'
					}
					style={[
						styles.box,
						isIconImageTile && styles.boxIconImage,
						{ width: tileSize, height: tileSize / tileAspectRatio },
					]}
				>
					{isAudioTile && (
						<View style={styles.audioIcon}>
							<WaveformIcon
								strokeWidth={2}
								fill={
									variant === TileCardVariant.meditation
										? BrandColor.primary_orange_lighter
										: BrandColor.neutral_300
								}
							/>
						</View>
					)}
					{isIconImageTile && !!image && (
						<Image
							source={image}
							style={styles.iconImage}
							format={imageFormat}
						/>
					)}
					{!isImageTile && !!title && (
						<View style={styles.title}>
							<Text
								style={styles.titleText}
								themeColor={
									variant === TileCardVariant.meditation
										? 'content_neutral800'
										: variant === TileCardVariant.exercise
											? 'content_on_fill'
											: 'content_primary'
								}
								weight="bold"
								numberOfLines={3}
							>
								{title}
							</Text>
						</View>
					)}
					{!!isImageTile && (
						<RNImage
							alt={title}
							source={image}
							style={StyleSheet.absoluteFill}
						/>
					)}
					{!!caption && <Caption title={caption} style={styles.caption} />}
					{isIconImageTile && !!subtitle && (
						<View style={styles.innerSubtitle}>
							<Text variant="body_lg" themeColor="content_neutral">
								<Text.CMSContent value={subtitle} />
							</Text>
						</View>
					)}
					{locked && (
						<View style={styles.lockedContainer}>
							<View style={styles.lockedIconContainer}>
								<LockKeyholeMinimalisticIcon
									color={BrandColor.white}
									size={iconSize.sm}
								/>
							</View>
						</View>
					)}
				</BackgroundView>
				{!!label && (
					<View style={styles.subtitle}>
						<Text variant="label_md" weight="regular">
							<Text.CMSContent value={label} />
						</Text>
					</View>
				)}
			</Pressable>
		</LinkProvider>
	);
};

const styles = StyleSheet.create({
	root: {
		marginRight: Spacing.dsDeviation__10,
	},
	rootIconImage: {
		marginRight: Spacing.large,
	},
	box: {
		borderRadius,
		overflow: 'hidden',
	},
	boxIconImage: {
		padding: Spacing.small,
	},
	title: {
		padding: Spacing.regular,
		flex: 1,
		justifyContent: 'flex-end',
	},
	titleText: {
		fontSize: 17,
		lineHeight: 20,
	},
	subtitle: {
		marginTop: Spacing.dsDeviation__10,
	},
	innerSubtitle: {
		paddingHorizontal: Spacing.regular,
		marginBottom: Spacing.regular,
	},
	iconImage: {
		width: 54,
		height: 54,
		marginLeft: Spacing.regular,
		marginTop: Spacing.regular,
	},
	noContent: {
		opacity: 0.5,
	},
	audioIcon: {
		...StyleSheet.absoluteFillObject,
		paddingVertical: Spacing.regular,
		opacity: 0.6,
	},
	caption: {
		margin: Spacing.small,
		marginTop: 0,
	},
	lockedContainer: {
		...StyleSheet.absoluteFillObject,
		backgroundColor: 'rgba(0, 0, 0, 0.5)',
		alignItems: 'flex-end',
		padding: Spacing.regular,
	},
	lockedIconContainer: {
		opacity: 0.75,
	},
});

export default TileCard;
