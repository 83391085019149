import { PortableTextComponentProps } from '@portabletext/react';
import { AccordionType } from '@wearemojo/sanity-schema';

import Accordion from '../Accordion';
import CMSContent from '../CMSContent';
import CMSBlock from './CMSBlock';

type CMSAccordionProps = PortableTextComponentProps<AccordionType>;
const CMSAccordion = ({ value: { items = [] } }: CMSAccordionProps) => (
	<CMSBlock textAlign="auto">
		<Accordion>
			{items.map(({ title, content }, index) => (
				<Accordion.Item key={index} title={title}>
					<CMSContent value={content} />
				</Accordion.Item>
			))}
		</Accordion>
	</CMSBlock>
);
export default CMSAccordion;
