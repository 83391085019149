import {
	Font,
	themeColors,
	UITheme,
	UIThemeColor,
} from '@wearemojo/ui-constants';
import { createContext, ReactNode, useContext, useMemo } from 'react';
import {
	StyleProp,
	StyleSheet,
	Text as RNText,
	TextProps,
	TextStyle,
} from 'react-native';

import CMSInlineContent from './CMSInlineContent';
import useUIContext from './hooks/useUIContext';
import {
	TextVariant,
	TextVariantish,
	TextWeight,
	TextWeightish,
	UIFormFactor,
} from './utils/types';
import {
	getFontFamilyStyle,
	getVariantStyle,
	underlineStyle,
} from './utils/typography';

const DEFAULT_TEXT_VARIANT = TextVariant.body_lg;

export type TextPropStyles = {
	variant?: TextVariantish;
	weight?: TextWeightish;
	italic?: boolean;
	underline?: boolean;
	strikethrough?: boolean;
	align?: 'auto' | 'left' | 'right' | 'center' | 'justify';
	themeColor?: UIThemeColor;
	style?: StyleProp<TextStyle>;
};

type Props = TextProps &
	TextPropStyles & {
		children: ReactNode;
	};

export const TextContext = createContext<TextPropStyles>({
	variant: undefined,
	weight: undefined,
	italic: undefined,
	underline: undefined,
	strikethrough: undefined,
	align: undefined,
	themeColor: undefined,
	style: undefined,
});

export const TextContextProvider = ({
	variant,
	weight,
	italic,
	underline,
	strikethrough,
	align,
	themeColor,
	style,
	children,
}: TextPropStyles & { children: ReactNode }) => {
	const inherited = useContext(TextContext);
	variant = variant ?? inherited.variant;
	weight = weight ?? inherited.weight;
	italic = italic ?? inherited.italic;
	underline = underline ?? inherited.underline;
	strikethrough = strikethrough ?? inherited.strikethrough;
	align = align ?? inherited.align;
	themeColor = themeColor ?? inherited.themeColor;
	style = style ?? inherited.style;

	const inherit = useMemo(
		() => ({
			variant,
			weight,
			italic,
			underline,
			strikethrough,
			align,
			themeColor,
			style,
		}),
		[
			variant,
			weight,
			italic,
			underline,
			strikethrough,
			align,
			themeColor,
			style,
		],
	);

	return (
		<TextContext.Provider value={inherit}>{children}</TextContext.Provider>
	);
};

const Text = ({
	variant,
	weight,
	italic,
	underline,
	strikethrough,
	align,
	themeColor,
	style,
	children,
	...textProps
}: Props) => {
	const inherited = useContext(TextContext);
	variant = variant ?? inherited.variant;
	weight = weight ?? inherited.weight;
	italic = italic ?? inherited.italic;
	underline = underline ?? inherited.underline;
	strikethrough = strikethrough ?? inherited.strikethrough;
	align = align ?? inherited.align;
	themeColor = themeColor ?? inherited.themeColor;
	style = style ?? inherited.style;

	const { formFactor, theme } = useUIContext();
	const styleKey = variantStyleKey(variant, formFactor);
	const variantStyle = styles[styleKey];
	const fontFamilyStyle = getFontFamilyStyle({
		variant: variant as TextVariant,
		weight: weight as TextWeight,
		italic,
	});

	const inherit = useMemo(
		() => ({
			variant,
			weight,
			italic,
			underline,
			strikethrough,
			align,
			themeColor,
			style,
		}),
		[
			variant,
			weight,
			italic,
			underline,
			strikethrough,
			align,
			themeColor,
			style,
		],
	);

	// @TODO: generate styles upfront (see variantStyles)? Will need to do so for every theme
	const colorStyle = themeColor
		? { color: themeColors[theme][themeColor] }
		: {};

	return (
		<RNText
			style={[
				styles.root,
				variantStyle,
				fontFamilyStyle,
				underline && styles.underline,
				strikethrough && styles.strikethrough,
				alignStyle(align),
				styles.themeDark,
				colorStyle,
				style,
			]}
			{...textProps}
		>
			<TextContext.Provider value={inherit}>{children}</TextContext.Provider>
		</RNText>
	);
};

const alignStyle = (align: Props['align']): TextStyle | undefined => {
	switch (align) {
		case 'left':
			return styles.alignLeft;
		case 'center':
			return styles.alignCenter;
		case 'right':
			return styles.alignRight;
		case 'justify':
			return styles.alignJustify;
		case 'auto':
			return styles.alignAuto;
	}
};

const variantStyleKey = (
	variant: TextVariantish = DEFAULT_TEXT_VARIANT,
	formFactor: UIFormFactor,
): `${TextVariant}__${UIFormFactor}` => `${variant}__${formFactor}`;

// Generate all variant styles (across all form factors) up front as part of the StyleSheet
const variantStyles = (Object.values(TextVariant) as Array<TextVariant>).reduce(
	(styles, variant) => {
		(Object.values(UIFormFactor) as Array<UIFormFactor>).forEach(
			(formFactor) => {
				const styleKey = variantStyleKey(variant, formFactor);
				styles[styleKey] = getVariantStyle(variant, formFactor);
			},
			{} as Record<UIFormFactor, TextStyle>,
		);
		return styles;
	},
	{} as Record<`${TextVariant}__${UIFormFactor}`, TextStyle>,
);

const styles = StyleSheet.create({
	root: {
		fontFamily: Font.body,
	},
	themeDark: {
		color: themeColors[UITheme.dark].content_on_dark,
	},
	underline: {
		...underlineStyle,
	},
	strikethrough: {
		textDecorationLine: 'line-through',
		textDecorationStyle: 'solid',
	},
	alignLeft: {
		textAlign: 'left',
	},
	alignCenter: {
		textAlign: 'center',
	},
	alignRight: {
		textAlign: 'right',
	},
	alignJustify: {
		textAlign: 'justify',
	},
	alignAuto: {
		textAlign: 'auto',
	},
	...variantStyles,
});

/*
	Text.CMSContent is a special variant of Text that takes CMS content of type
	InlineContent, but is otherwise compatible with being used as (or within) a
	usual Text component.

	Note: one major difference is that it does not take children, rather it takes
	type InlineContent via a `value` prop. It may also render multiple (nested)
	Text components, and a few other span style components depending on the input.
*/
Text.CMSContent = CMSInlineContent;

Text.TextContextProvider = TextContextProvider;

export default Text;
