import React, { ReactNode } from 'react';
import {
	Falsy,
	ScrollView as RNScrollView,
	StyleProp,
	StyleSheet,
	View as RNView,
	ViewStyle,
} from 'react-native';

import { ScrollViewRef } from './utils/types';

export type Props = {
	children: ReactNode;
	style?: StyleProp<ViewStyle> | Falsy;
	innerStyle?: StyleProp<ViewStyle> | Falsy;
	scrollDirection?: ScrollDirection;
} & ScrollViewRef;

export type ScrollDirection = 'vertical' | 'horizontal' | 'both';

const bounceProps = {
	alwaysBounceVertical: false,
	alwaysBounceHorizontal: false,
};

const ScrollContainer = ({
	children,
	style,
	scrollDirection,
	scrollViewRef,
}: Props) => {
	if (scrollDirection === 'both') {
		return (
			<RNView style={style}>
				<RNScrollView
					ref={scrollViewRef}
					contentContainerStyle={styles.vertical}
					nestedScrollEnabled
					{...bounceProps}
				>
					<RNScrollView
						contentContainerStyle={styles.horizontal}
						horizontal={true}
						{...bounceProps}
					>
						{children}
					</RNScrollView>
				</RNScrollView>
			</RNView>
		);
	}
	return (
		<RNView style={style}>
			<RNScrollView
				ref={scrollViewRef}
				contentContainerStyle={[
					scrollDirection === 'horizontal'
						? styles.horizontal
						: styles.vertical,
				]}
				horizontal={scrollDirection === 'horizontal'}
				{...bounceProps}
			>
				{children}
			</RNScrollView>
		</RNView>
	);
};

const styles = StyleSheet.create({
	vertical: {
		flexGrow: 1,
	},
	horizontal: {
		minWidth: '100%',
	},
});

export default ScrollContainer;
