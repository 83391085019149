import ExclamationIcon from '@wearemojo/icons/Outline/EssentionalUI/DangerTriangleIcon';
import { ReactNode } from 'react';

import CalloutCard from './CalloutCard';

export type Props = {
	title: string;
	children: ReactNode;
};

const WarningCard = ({ title, children }: Props) => {
	return (
		<CalloutCard title={title} icon={ExclamationIcon} type="yellow">
			{children}
		</CalloutCard>
	);
};

export default WarningCard;
