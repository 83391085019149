import { BrandColor } from '@wearemojo/ui-constants';
import { LinearGradient } from 'expo-linear-gradient';
import { ReactNode } from 'react';
import { Image, ImageURISource, View } from 'react-native';
import { createStyleSheet, useStyles } from 'react-native-unistyles';

import ShieldCheckGradientIcon from './icons/ShieldCheckGradientIcon';
import Text from './Text';
import TextMask from './TextMask';

type Props = {
	author: string;
	isVerified?: boolean;
	byline?: string;
	children: ReactNode;
	authorImage?: ImageURISource;
};

const ExpertQuote = ({
	children,
	isVerified,
	author,
	byline,
	authorImage,
}: Props) => {
	const { styles } = useStyles(stylesheet);
	return (
		<LinearGradient
			colors={[
				BrandColor.primary_blue_lighter,
				BrandColor.primary_yellow_lighter,
			]}
			start={{ x: 0, y: 0 }}
			end={{ x: 1, y: 0 }}
			style={styles.container}
		>
			<View style={styles.root}>
				{isVerified && (
					<View style={styles.verifiedContainer}>
						<ShieldCheckGradientIcon />
						<TextMask
							colors={[
								BrandColor.primary_blue_lighter,
								BrandColor.primary_yellow_lighter,
							]}
							locations={[0, 1]}
							variant="heading_xxs_upper"
						>
							verified expert
						</TextMask>
					</View>
				)}

				{children}
				<View style={styles.authorContainer}>
					{!!authorImage?.uri && (
						<View style={styles.authorImageContainer}>
							<Image
								source={authorImage}
								style={styles.authorImage}
								alt="author headshot"
							/>
							{isVerified && (
								<View style={styles.authorImageIconContainer}>
									<ShieldCheckGradientIcon size={12} />
								</View>
							)}
						</View>
					)}
					<View style={styles.authorTextContainer}>
						<Text variant="heading_xxs_upper">{author}</Text>
						{byline ? (
							<Text variant="heading_xxs_upper" themeColor="content_neutral">
								{byline}
							</Text>
						) : null}
					</View>
				</View>
			</View>
		</LinearGradient>
	);
};

const stylesheet = createStyleSheet(({ spacing, radius, colors }) => ({
	container: {
		paddingTop: 1,
		borderRadius: radius.md,
	},
	root: {
		padding: spacing.lg,
		borderRadius: radius.md,
		gap: spacing.xs,
		backgroundColor: colors.background_level_1,
		alignItems: 'center',
	},
	content: {
		flex: 1,
		padding: spacing.lg,
	},
	authorContainer: {
		marginTop: spacing.md,
		gap: spacing.xs,
		alignItems: 'center',
		justifyContent: 'center',
	},
	authorTextContainer: {
		alignItems: 'center',
	},
	authorImage: {
		height: 48,
		width: 48,
		borderRadius: 48 / 2,
		marginRight: spacing.md,
	},
	authorImageContainer: {
		height: 48,
		width: 48,
		position: 'relative',
	},
	authorImageIconContainer: {
		position: 'absolute',
		bottom: 0,
		right: 0,
	},
	verifiedContainer: {
		flexDirection: 'row',
		alignItems: 'center',
		gap: spacing.xs,
		justifyContent: 'center',
	},
}));

export default ExpertQuote;
