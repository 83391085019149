import { createContext, useCallback, useContext, useEffect } from 'react';

export enum ScreenEvent {
	blur = 'blur',
	focus = 'focus',
	navigateIn = 'navigateIn',
	navigateOut = 'navigateOut',
}

type ScreenEventListener = () => void;
type ScreenEventContext = {
	subscriptions: ScreenEventSubscriptions;
};
type ScreenEventSubscriptions = Record<ScreenEvent, ScreenEventListener[]>;

export const createScreenEventsContext = (): ScreenEventContext => {
	const subscriptions = (Object.keys(ScreenEvent) as ScreenEvent[]).reduce(
		(acc, event) => {
			acc[event] = [];
			return acc;
		},
		{} as ScreenEventSubscriptions,
	);
	return {
		subscriptions,
	};
};

export const ScreenEventsContext = createContext(createScreenEventsContext());

export const useScreenEvents = () => {
	const { subscriptions } = useContext(ScreenEventsContext);

	const on = useCallback(
		(event: ScreenEvent, listener: ScreenEventListener) => {
			const index = subscriptions[event].push(listener) - 1;
			return () => {
				subscriptions[event].splice(index, 1);
			};
		},
		[subscriptions],
	);

	const dispatch = useCallback(
		(event: ScreenEvent) => {
			subscriptions[event].forEach((listener) => listener());
		},
		[subscriptions],
	);

	return {
		on,
		dispatch,
	};
};

// Shortcut to useScreenEvents().on('event', listener)
export const useScreenEvent = (
	event: ScreenEvent,
	listener: ScreenEventListener,
) => {
	const { on } = useScreenEvents();
	useEffect(() => on(event, () => listener()), [on, event, listener]);
};
