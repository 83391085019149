import { Font } from '@wearemojo/ui-constants';
import { Platform, StyleProp, TextStyle } from 'react-native';

import { TextVariant, TextWeight, UIFormFactor } from './types';

export const monospaceFont =
	Platform.OS === 'ios' ? 'Courier New' : 'monospace';

export const baseSizes = {
	headingXlUpper: {
		fontSize: 72,
		lineHeight: 60,
		textTransform: 'uppercase' as const,
	},
	headingLgUpper: {
		fontSize: 64,
		lineHeight: 52,
		textTransform: 'uppercase' as const,
	},
	headingMdUpper: {
		fontSize: 44,
		lineHeight: 40,
		textTransform: 'uppercase' as const,
	},
	headingSmUpper: {
		fontSize: 32,
		lineHeight: 26,
		textTransform: 'uppercase' as const,
	},
	headingXsUpper: {
		fontSize: 20,
		lineHeight: 20,
		textTransform: 'uppercase' as const,
	},
	headingXxsUpper: {
		fontSize: 14,
		lineHeight: 14,
		textTransform: 'uppercase' as const,
	},
	headingXl: { fontSize: 72 },
	headingLg: { fontSize: 64 },
	headingMd: { fontSize: 44 },
	headingSm: { fontSize: 32 },
	headingXs: { fontSize: 20 },
	headingXxs: { fontSize: 14 },
	textXl: { fontSize: 24, lineHeight: 30 },
	textBase: { fontSize: 16, lineHeight: 26 },
	textSm: { fontSize: 14, lineHeight: 22 },
	textXs: { fontSize: 12, lineHeight: 18 },
};

const normalStyles: Record<TextWeight, TextStyle> = {
	[TextWeight.heading]: { fontFamily: Font.heading },
	[TextWeight.headingBold]: { fontFamily: Font.headingBold },
	[TextWeight.headingExtraBold]: { fontFamily: Font.headingExtraBold },
	[TextWeight.regular]: { fontFamily: Font.body },
	[TextWeight.bold]: { fontFamily: Font.bodyBold },
};

const italicStyles: Record<TextWeight, TextStyle> = {
	[TextWeight.heading]: { fontFamily: Font.headingItalic },
	[TextWeight.headingBold]: { fontFamily: Font.headingBoldItalic },
	[TextWeight.headingExtraBold]: { fontFamily: Font.headingExtraBoldItalic },
	[TextWeight.regular]: { fontFamily: Font.bodyItalic },
	[TextWeight.bold]: { fontFamily: Font.bodyBoldItalic },
};

export const underlineStyle: TextStyle = {
	textDecorationLine: 'underline',
};

export const getFontFamilyStyle = ({
	variant,
	weight,
	italic = false,
}: {
	variant: TextVariant;
	weight?: TextWeight;
	italic?: boolean;
}): StyleProp<TextStyle> => {
	const key = weight ?? getVariantDefaultTextWeight(variant);
	return italic ? italicStyles[key] : normalStyles[key];
};

export const getVariantDefaultTextWeight = (
	variant: TextVariant,
): TextWeight => {
	switch (variant) {
		case TextVariant.heading_xl:
		case TextVariant.heading_lg:
		case TextVariant.heading_md:
		case TextVariant.heading_sm:
		case TextVariant.heading_xl_upper:
		case TextVariant.heading_lg_upper:
		case TextVariant.heading_md_upper:
		case TextVariant.heading_sm_upper:
			return TextWeight.headingExtraBold;

		case TextVariant.heading_xs:
		case TextVariant.heading_xs_upper:
		case TextVariant.action:
			return TextWeight.headingBold;

		case TextVariant.heading_xxs:
		case TextVariant.heading_xxs_upper:
			return TextWeight.heading;

		case TextVariant.label_md:
		case TextVariant.label_sm:
		case TextVariant.body_xl:
		case TextVariant.body_lg:
			return TextWeight.bold;

		default:
			return TextWeight.regular;
	}
};

export const VariantBaseStylesMap: Record<
	TextVariant,
	TextStyle & Partial<Record<UIFormFactor, TextStyle>>
> = {
	heading_xl: baseSizes.headingXl,
	heading_lg: baseSizes.headingLg,
	heading_md: baseSizes.headingMd,
	heading_sm: baseSizes.headingSm,
	heading_xs: baseSizes.headingXs,
	heading_xxs: baseSizes.headingXxs,
	heading_xl_upper: baseSizes.headingXlUpper,
	heading_lg_upper: baseSizes.headingLgUpper,
	heading_md_upper: baseSizes.headingMdUpper,
	heading_sm_upper: baseSizes.headingSmUpper,
	heading_xs_upper: baseSizes.headingXsUpper,
	heading_xxs_upper: baseSizes.headingXxsUpper,
	body_xl: baseSizes.textXl,
	body_lg: baseSizes.textBase,
	body_md: baseSizes.textSm,
	body_sm: baseSizes.textXs,
	label_md: baseSizes.textBase, // TODO: this should be removed after the rebrand is complete
	label_sm: baseSizes.textXs, // TODO: this should be removed after the rebrand is complete
	action: baseSizes.textBase,
};

export const getVariantStyle = (
	variant: TextVariant,
	formFactor: UIFormFactor,
): TextStyle => {
	const {
		mobile = {},
		desktop = {},
		...shared
	} = VariantBaseStylesMap[variant];
	const weight = getVariantDefaultTextWeight(variant);
	return {
		...normalStyles[weight],
		...(shared ?? {}),
		...(formFactor === UIFormFactor.desktop ? desktop : mobile),
	};
};
