import { Path, Svg } from 'react-native-svg';

import useUITheme from '../hooks/useUITheme';

export default ({
	fill: _fill,
	strokeWidth = 2,
}: {
	fill?: string;
	strokeWidth?: number;
}) => {
	const defaultFill = useUITheme().border_primary;
	const fill = _fill ?? defaultFill;
	return (
		<Svg viewBox="0 0 12 10" fill="none">
			<Path
				d="M1.33325 5.6665L3.99992 8.33317L10.6666 1.6665"
				stroke={fill}
				strokeWidth={strokeWidth}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</Svg>
	);
};
