import { PortableTextComponentProps } from '@portabletext/react';
import { ContentSwitcherBlockType } from '@wearemojo/sanity-schema';

import CMSContent from '../CMSContent';
import ContentSwitcher, { useContentSwitcher } from '../ContentSwitcher';
import CMSBlock from './CMSBlock';

type CMSContentSwitcherProps =
	PortableTextComponentProps<ContentSwitcherBlockType>;

const CMSContentSwitcher = ({
	value: { choices, dynamicContent },
}: CMSContentSwitcherProps) => {
	const { selectedChoices, onOptionPress, content } = useContentSwitcher(
		choices,
		dynamicContent,
	);

	return (
		<CMSBlock textAlign="auto">
			<ContentSwitcher choices={selectedChoices} onOptionPress={onOptionPress}>
				<CMSContent value={content?.content} />
			</ContentSwitcher>
		</CMSBlock>
	);
};

export default CMSContentSwitcher;
