import { ReactNode, useEffect } from 'react';
import { StyleSheet, useWindowDimensions } from 'react-native';
import Animated, {
	useAnimatedKeyboard,
	useAnimatedStyle,
	useDerivedValue,
	useSharedValue,
} from 'react-native-reanimated';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import useUIContext from './hooks/useUIContext';

type Props = {
	children: ReactNode;
	insideSafeArea?: boolean;
};

const options = {
	isStatusBarTranslucentAndroid: true,
	isNavigationBarTranslucentAndroid: true,
};

const useKeyboardHeight = () => {
	const { height: keyboardHeight } = useAnimatedKeyboard(options);
	const { height: windowHeight } = useWindowDimensions();
	const { prefersCrossFadeTransitions } = useUIContext();
	return useDerivedValue(() => {
		/**
		 * useAnimatedKeyboard gives incorrect value when prefersCrossFadeTransitions is enabled
		 * https://github.com/software-mansion/react-native-reanimated/issues/6440
		 *
		 * keyboardHeight equals windowHeight when keyboard is hidden, assumption is this is the Y offset of the keyboard,
		 * value otherwise looks to behave correctly when keyboard is shown
		 */
		if (prefersCrossFadeTransitions && keyboardHeight.value === windowHeight) {
			return 0;
		}
		return keyboardHeight.value;
	});
};

const ViaTranslate = ({ children }: Props) => {
	const keyboardHeight = useKeyboardHeight();
	const animatedStyles = useAnimatedStyle(() => ({
		transform: [{ translateY: -keyboardHeight.value }],
	}));

	return (
		<Animated.View style={[styles.root, animatedStyles]}>
			{children}
		</Animated.View>
	);
};

const ViaPadding = ({ children, insideSafeArea }: Props) => {
	const keyboardHeight = useKeyboardHeight();
	const insets = useSafeAreaInsets();
	const bottomSafeArea = useSharedValue(insideSafeArea ? insets.bottom : 0);
	useEffect(() => {
		bottomSafeArea.value = insideSafeArea ? insets.bottom : 0;
	}, [insideSafeArea, bottomSafeArea, insets.bottom]);

	const animatedStyles = useAnimatedStyle(() => ({
		paddingBottom: Math.max(0, keyboardHeight.value - bottomSafeArea.value),
	}));

	return (
		<Animated.View style={[styles.root, animatedStyles]}>
			{children}
		</Animated.View>
	);
};

const styles = StyleSheet.create({
	root: {
		flex: 1,
	},
});

export default {
	ViaTranslate,
	ViaPadding,
};
