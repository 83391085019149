import { PortableTextComponentProps } from '@portabletext/react';
import { ChecklistType } from '@wearemojo/sanity-schema';

import Checklist, { ChecklistItem } from '../Checklist';
import CMSBlock from './CMSBlock';

type CMSChecklistProps = PortableTextComponentProps<ChecklistType>;
const CMSChecklist = ({ value: { items = [] } }: CMSChecklistProps) => (
	<CMSBlock textAlign="auto">
		<Checklist>
			{items.map(({ checked, title }, index) => (
				<ChecklistItem key={index} checked={checked}>
					{title}
				</ChecklistItem>
			))}
		</Checklist>
	</CMSBlock>
);

export default CMSChecklist;
