import { Spacing, themeColors, UITheme } from '@wearemojo/ui-constants';
import { StyleSheet, View } from 'react-native';

const LineBreak = () => <View style={styles.root} />;

const styles = StyleSheet.create({
	root: {
		borderTopWidth: 1,
		borderColor: themeColors[UITheme.dark].border_neutral,
		marginVertical: Spacing.small,
	},
});

export default LineBreak;
