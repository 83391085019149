import { useEffect, useState } from 'react';

import { CaptureError } from './errors';

/**
 *	Array of minimum and maximum waveform data points interleaved * channels
 *	- single channel: [minIndex0, maxIndex0, minIndex1, maxIndex1, ...]
 *	- dual/2 channels: [minIndex0Chan0, maxIndex0Chan0, minIndex0Chan1, maxIndex0Chan1, minIndex1Chan0, ...]
 *
 * See `waveform.test.ts` for illustrative examples of the format and the functions that act on it.
 *
 * Overall data format relates to the JSON data format generated by the `audiowaveform` tool:
 * 	https://github.com/bbc/audiowaveform/blob/e26bb08d9014e46a9ea0324e492e20640e866b1d/doc/DataFormat.md#json-data-format-json
 *
 */
type DataArray = number[];

export type WaveformData = {
	data: DataArray;
	channels: number;
	bits: number;
};

type LoadAudioWaveformOptions = {
	skip?: boolean;
	captureError?: CaptureError;
};

export const useLoadAudioWaveformData = (
	uri?: string,
	options: LoadAudioWaveformOptions = {},
): WaveformData | undefined => {
	const [waveformData, setWaveformData] = useState<WaveformData | undefined>();

	useEffect(() => {
		if (!uri && !!waveformData) {
			setWaveformData(undefined);
		}
	}, [waveformData, uri]);

	const { skip, captureError } = options;
	useEffect(() => {
		if (!uri || skip) return;

		const fetchData = async () => {
			try {
				const response = await fetch(uri);
				const data = await response.json();
				setWaveformData(data);
			} catch (error) {
				const message = `Failed to fetch waveform data from ${uri}`;
				console.error(message);
				captureError?.(message, { error });
				setWaveformData(undefined);
			}
		};
		fetchData();
	}, [uri, skip, captureError]);

	return waveformData;
};

/**
 * Split out interleaved waveform data into it's respective channels
 */
export function splitChannels(data: DataArray, channelCount = 1): DataArray[] {
	const channels = Array.from({ length: channelCount }, () => [] as DataArray);

	if (data.length % 2 !== 0) {
		throw new Error(
			'Data must be an even number as channels are made up of tuples (e.g. [min, max, ...]',
		);
	}

	for (let i = 0; i < data.length; i += 2) {
		const channelIndex = Math.floor(i / 2) % channelCount;
		channels[channelIndex]!.push(data[i]!, data[i + 1]!);
	}

	return channels;
}

/**
 * Take separate channels and interleave them into a single waveform data array
 */
export function interleaveChannels(channels: DataArray[]): DataArray {
	const interleaved: DataArray = [];
	const max = channels[0]!.length;

	if (channels.some((channel) => channel.length !== max)) {
		throw new Error('All channels must match in length');
	}

	if (channels[0]!.length % 2 !== 0) {
		throw new Error(
			'Length of channels must be an even number as they are made up of tuples (e.g. [min, max, ...]',
		);
	}

	for (let i = 0; i < max; i += 2) {
		for (let channel of channels) {
			interleaved.push(channel[i]!, channel[i + 1]!);
		}
	}

	return interleaved;
}

/**
 * Rotate waveform data by a given offset, e.g. [1, 2, 3, 4] rotated by 2 becomes [3, 4, 1, 2]
 */
export function rotate(data: DataArray, offset: number): DataArray {
	return [...data.slice(offset), ...data.slice(0, offset)];
}

/**
 * Resample waveform data to a target number of points
 */
export function resample(
	data: DataArray,
	targetPoints: number,
	channels = 1,
): DataArray {
	if (channels > 1) {
		const channelsData = splitChannels(data, channels);
		const channelsResampled = channelsData.map((channel) =>
			resample(channel, targetPoints, 1),
		);

		return interleaveChannels(channelsResampled);
	}

	const resampled: DataArray = [];
	const points = data.length;

	if (points % 2 !== 0 || targetPoints % 2 !== 0) {
		throw new Error(
			'An even number of data points is required, tuples expected e.g. [min0, max0, min1, max1, ...]',
		);
	}

	if (points === targetPoints) {
		return data;
	}

	const tupleCount = points / 2;
	const factor = tupleCount / targetPoints;

	for (let i = 0; i < targetPoints; i += 2) {
		const startTuple = Math.floor(i * factor);
		const endTuple = Math.min(Math.floor((i + 1) * factor), tupleCount);

		let minSum = 0;
		let maxSum = 0;
		let count = 0;

		for (let j = startTuple; j < endTuple; j++) {
			const minValue = data[2 * j]!;
			const maxValue = data[2 * j + 1]!;
			minSum += minValue;
			maxSum += maxValue;
			count++;
		}

		const averagedMin = count > 0 ? minSum / count : data[2 * startTuple]!;
		const averagedMax = count > 0 ? maxSum / count : data[2 * startTuple + 1]!;

		resampled.push(averagedMin, averagedMax);
	}

	return resampled;
}

/**
 * Normalize a value from one range to another
 */
export function normalizeValue(
	x: number,
	originalMin: number,
	originalMax: number,
	newMin: number,
	newMax: number,
): number {
	return (
		((x - originalMin) / (originalMax - originalMin)) * (newMax - newMin) +
		newMin
	);
}
