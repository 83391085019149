import { Spacing } from '@wearemojo/ui-constants';
import { useEffect, useState } from 'react';
import { ActivityIndicator, StyleSheet, View } from 'react-native';

import useUITheme from './hooks/useUITheme';
import Text from './Text';
import {
	InteractionEvent,
	useInteractionEvents,
} from './utils/interactionEvents';

type Props = {
	label: string;
};

enum LoadingStage {
	Preloading = 'preloading',
	Loading = 'loading',
	Slow = 'slow',
}

const LoadingIndicator = ({ label }: Props) => {
	const { dispatch } = useInteractionEvents();
	const color = useUITheme().fill_highlight;

	const [loadingStage, setLoadingStage] = useState(LoadingStage.Preloading);

	useEffect(() => {
		const preloadingTimeout = setTimeout(() => {
			setLoadingStage(LoadingStage.Loading);
		}, 100);

		const slowTimeout = setTimeout(() => {
			setLoadingStage(LoadingStage.Slow);
		}, 5000);

		return () => {
			clearTimeout(preloadingTimeout);
			clearTimeout(slowTimeout);
		};
	}, []);

	useEffect(() => {
		if (loadingStage !== LoadingStage.Slow) {
			return;
		}

		console.debug(
			`Loading indicator "${label}" has been in slow stage for 5 seconds.`,
		);

		dispatch(InteractionEvent.loading_indicator_persisted, { label });
	}, [loadingStage, dispatch, label]);

	if (loadingStage === LoadingStage.Preloading) {
		/* Helps reduce the occurance of flashing loading indicators */
		return null;
	}

	const showLabel = __DEV__ || loadingStage === LoadingStage.Slow;

	return (
		<View style={styles.loader}>
			<ActivityIndicator size="large" color={color} />
			{showLabel && (
				<View style={styles.label}>
					<Text themeColor="content_neutral">{label}</Text>
				</View>
			)}
		</View>
	);
};

const styles = StyleSheet.create({
	loader: {
		flex: 1,
		justifyContent: 'center',
	},
	label: {
		alignSelf: 'center',
		marginTop: Spacing.regular,
	},
});

export default LoadingIndicator;
