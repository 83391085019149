import { PortableTextComponentProps } from '@portabletext/react';
import { CheckoutPriceBlockType } from '@wearemojo/sanity-schema';

import CheckoutPriceBlock from '../CheckoutPriceBlock';
import CMSBlock from './CMSBlock';

type Props = PortableTextComponentProps<CheckoutPriceBlockType>;
const CMSCheckoutPriceBlock = ({
	value: { label, originalPrice, price },
}: Props) => {
	return (
		<CMSBlock textAlign="auto">
			<CheckoutPriceBlock
				label={label}
				price={price}
				originalPrice={originalPrice}
			/>
		</CMSBlock>
	);
};

export default CMSCheckoutPriceBlock;
