import { InlineContentType } from '@wearemojo/sanity-schema';
import { BrandColor } from '@wearemojo/ui-constants';
import { ReactNode } from 'react';
import { View } from 'react-native';
import { createStyleSheet, useStyles } from 'react-native-unistyles';

import Image from '../Image';
import Text from '../Text';

type Props = {
	content: InlineContentType;
	image?: { uri?: string };
	leadingItem?: ReactNode;
};

const ContentCard = ({ content, image, leadingItem }: Props) => {
	const { styles } = useStyles(stylesheet);

	return (
		<View style={styles.root}>
			{(!!leadingItem || !!image) && (
				<View style={styles.iconBox}>
					{leadingItem}
					{image && !leadingItem && (
						<Image
							source={image}
							style={styles.image}
							resizeMode="cover"
							format="svg"
						/>
					)}
				</View>
			)}
			<View style={styles.content}>
				<Text.CMSContent
					value={content}
					variant="body_md"
					themeColor="content_neutral100"
				/>
			</View>
		</View>
	);
};

const ICON_HEIGHT = 40;

const stylesheet = createStyleSheet(({ radius, spacing }) => ({
	root: {
		borderRadius: radius.md,
		padding: spacing.xl,
		backgroundColor: BrandColor.neutral_600,
		gap: spacing.md,
		alignItems: 'flex-start',
		flexDirection: 'row',
	},
	iconBox: {
		height: ICON_HEIGHT,
		width: ICON_HEIGHT,
		borderRadius: ICON_HEIGHT / 2,
		backgroundColor: BrandColor.neutral_700,
		justifyContent: 'center',
		alignItems: 'center',
	},
	content: {
		flex: 1,
		justifyContent: 'center',
		minHeight: ICON_HEIGHT,
	},
	image: {
		height: 24,
		width: 24,
	},
}));

export default ContentCard;
