import InfoCircleIcon from '@wearemojo/icons/Outline/EssentionalUI/InfoCircleIcon';
import { ReactNode } from 'react';

import CalloutCard from './CalloutCard';

type Props = {
	title?: string;
	children: ReactNode;
};

const InfoCard = ({ title, children }: Props) => {
	return (
		<CalloutCard title={title} icon={InfoCircleIcon} type="green">
			{children}
		</CalloutCard>
	);
};

export default InfoCard;
