import { borderRadius, SCREEN_MAX_WIDTH_SM } from '@wearemojo/ui-constants';
import { ComponentType, PropsWithChildren } from 'react';
import { Dimensions, ScrollView, View } from 'react-native';
import { createStyleSheet, useStyles } from 'react-native-unistyles';

import BackgroundView from './BackgroundView';
import ContainedAction from './ContainedAction';
import Text from './Text';
import { Heroicon } from './utils/types';

type Props = {
	onClosePress?: () => void;
	icon?: ComponentType<Heroicon>;
	title?: string;
} & PropsWithChildren;

const PopupInfo = ({ onClosePress, icon: Icon, title, children }: Props) => {
	const { styles, theme } = useStyles(stylesheet);

	return (
		<View style={styles.root}>
			<BackgroundView
				background="background_secondary"
				style={styles.container}
			>
				<View style={styles.header}>
					<View style={styles.titleIconContainer}>
						{Icon && (
							<Icon stroke={theme.colors.content_primary} style={styles.icon} />
						)}
						{title && <Text variant="heading_xs">{title}</Text>}
					</View>
					{onClosePress && (
						<ContainedAction type="close" onPress={onClosePress} />
					)}
				</View>

				<ScrollView showsVerticalScrollIndicator={false}>{children}</ScrollView>
			</BackgroundView>
		</View>
	);
};

const stylesheet = createStyleSheet(({ spacing }) => ({
	root: {
		backgroundColor: 'rgba(0, 0, 0, 0.7)',
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
		paddingHorizontal: spacing.xl3,
	},
	header: {
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	icon: {
		marginRight: spacing.xs,
	},
	titleIconContainer: {
		flex: 1,
		flexDirection: 'row',
		alignItems: 'center',
	},
	container: {
		borderRadius,
		padding: spacing.xl,
		maxWidth: SCREEN_MAX_WIDTH_SM,
		alignSelf: 'center',
		width: '100%',
		maxHeight: Dimensions.get('window').height * 0.8,
		gap: spacing.md,
	},
}));

export default PopupInfo;
