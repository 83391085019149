import { StyleSheet, View, ViewProps } from 'react-native';

type Props = {
	aspectRatio?: number;
} & ViewProps;

const ViewRatio = ({ aspectRatio, children, style, ...viewProps }: Props) => {
	return (
		<View style={[styles.root, { aspectRatio }, style]} {...viewProps}>
			{children}
		</View>
	);
};

const styles = StyleSheet.create({
	root: {
		width: '100%',
	},
});

export default ViewRatio;
