import { FragmentMagicLinkRequesterType } from '@wearemojo/sanity-schema';
import {
	BorderRadius,
	SCREEN_MAX_WIDTH_SM,
	Spacing,
	themeColors,
	UITheme,
} from '@wearemojo/ui-constants';
import React, { ReactNode, useState } from 'react';
import { SafeAreaView, ScrollView, StyleSheet, View } from 'react-native';
import {
	CodeField,
	Cursor,
	useClearByFocusCell,
} from 'react-native-confirmation-code-field';

import BackgroundView from '../BackgroundView';
import Button from '../Button';
import useUIContext from '../hooks/useUIContext';
import Stack from '../Stack';
import Text from '../Text';

type Props = {
	navHeader: ReactNode;
	magicLinkRequesterFragment: FragmentMagicLinkRequesterType;
	onExchangeMagicLinkCode: (code: string) => Promise<void>;
	codeLength: number;
};

const MagicLinkRequesterView = ({
	navHeader,
	magicLinkRequesterFragment,
	onExchangeMagicLinkCode,
	codeLength,
}: Props) => {
	const { isDesktop } = useUIContext();

	const [confirming, setConfirming] = useState(false);

	const [codeInputValue, setCodeInputValue] = useState<string>();
	const [codeFieldProps, getCellOnLayout] = useClearByFocusCell({
		value: codeInputValue,
		setValue: setCodeInputValue,
	});

	const onConfirm = async () => {
		if (!codeInputValue) return;
		setConfirming(true);
		await onExchangeMagicLinkCode(codeInputValue);
		setConfirming(false);
	};

	return (
		<SafeAreaView style={styles.safeAreaView}>
			<BackgroundView
				background="background_primary"
				style={StyleSheet.absoluteFillObject}
			/>
			<View style={styles.root}>
				{navHeader}
				<ScrollView>
					<View
						style={[styles.container, isDesktop && styles.containerDesktop]}
					>
						<Stack spacing={Spacing.dsDeviation__20}>
							<Stack spacing={Spacing.small}>
								<Text.CMSContent
									variant="heading_sm"
									value={magicLinkRequesterFragment.title}
									contentVariables={{ codeLength }}
								/>
								<Text.CMSContent
									value={magicLinkRequesterFragment.description}
									contentVariables={{ codeLength }}
								/>
							</Stack>
							<CodeField
								{...codeFieldProps}
								value={codeInputValue}
								onChangeText={setCodeInputValue}
								cellCount={codeLength}
								rootStyle={styles.codeFieldRoot}
								keyboardType="number-pad"
								textContentType="oneTimeCode"
								renderCell={({ index, symbol, isFocused }) => (
									<View
										key={index}
										style={[styles.cell, isFocused && styles.focusedCell]}
										onLayout={getCellOnLayout(index)}
									>
										<Text variant="heading_md">
											{symbol || (isFocused ? <Cursor /> : '-')}
										</Text>
									</View>
								)}
								onSubmitEditing={onConfirm}
							/>
							<Button
								title={magicLinkRequesterFragment.confirmCta}
								type={'primary'}
								loading={confirming}
								onPress={onConfirm}
							/>
						</Stack>
					</View>
				</ScrollView>
			</View>
		</SafeAreaView>
	);
};

const styles = StyleSheet.create({
	safeAreaView: {
		flex: 1,
	},
	root: {
		flex: 1,
	},
	container: {
		paddingTop: Spacing.extraLarge,
		paddingHorizontal: Spacing.large,
	},
	containerDesktop: {
		maxWidth: SCREEN_MAX_WIDTH_SM,
		marginHorizontal: 'auto',
	},
	codeFieldRoot: { justifyContent: 'center' },
	cell: {
		borderRadius: BorderRadius.small,
		borderColor: themeColors[UITheme.dark].background_secondary,
		borderWidth: 2,
		width: 64,
		height: 64,
		justifyContent: 'center',
		alignItems: 'center',
		marginHorizontal: 10,
	},
	focusedCell: {
		borderColor: themeColors[UITheme.dark].border_primary,
	},
});

export default MagicLinkRequesterView;
