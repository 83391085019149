import { ReactNode, useMemo } from 'react';
import { Animated, View } from 'react-native';
import { Edge, SafeAreaView } from 'react-native-safe-area-context';
import { createStyleSheet, useStyles } from 'react-native-unistyles';

import Belt from './Belt';
import ContainedAction from './ContainedAction';
import useUIContext from './hooks/useUIContext';
import Text from './Text';

export type Props = {
	title?: string;
	onPressClose?: () => void;
	progress?: number;
	progressSteps?: number;
	safeAreaEdges?: Array<Edge>;
	noBorder?: boolean;
	showBackIcon?: boolean; // @TODO: modifies icon shown for onPressClose, naming could be better
	supportButton?: ReactNode;
	transparent?: boolean;
};

const ModalHeader = ({
	title,
	onPressClose,
	progress,
	progressSteps,
	safeAreaEdges,
	noBorder = false,
	showBackIcon = false,
	supportButton,
	transparent = false,
}: Props) => {
	const { styles } = useStyles(stylesheet, { transparent });
	const { isIos } = useUIContext();
	const hasProgressStep = progress !== undefined && progressSteps !== undefined;
	const progressFraction = hasProgressStep ? (1 / progressSteps) * progress : 0;
	const defaultEdges: Array<Edge> = useMemo(
		// Modals on iOS will (typically) open in a sheet view.
		() => (isIos ? ['left', 'right'] : ['left', 'top', 'right']),
		[isIos],
	);

	return (
		<View style={styles.root}>
			<Belt>
				<SafeAreaView
					edges={safeAreaEdges ?? defaultEdges}
					mode="margin"
					style={[
						styles.inner,
						hasProgressStep && styles.innerHasProgressStep,
						noBorder && styles.innerNoBorder,
					]}
				>
					<View style={styles.title}>{!!title && <Title title={title} />}</View>
					{hasProgressStep && (
						<Animated.View
							style={[
								styles.progressStep,
								{ width: `${progressFraction * 100}%` },
							]}
						/>
					)}
					{supportButton}
					{onPressClose && (
						<View style={styles.closeContainer}>
							<ContainedAction
								type={showBackIcon ? 'back' : 'close'}
								onPress={onPressClose}
							/>
						</View>
					)}
				</SafeAreaView>
			</Belt>
		</View>
	);
};

const Title = ({ title }: { title: string }) => (
	<Text variant="label_md" weight="bold" numberOfLines={1}>
		{title}
	</Text>
);

const PROGRESS_BAR_HEIGHT = 4;

const stylesheet = createStyleSheet(({ colors, spacing }) => ({
	root: {
		variants: {
			transparent: {
				true: {
					backgroundColor: 'transparent',
				},
				false: {
					backgroundColor: colors.background_primary,
				},
			},
		},
	},
	inner: {
		minHeight: 76,
		alignItems: 'center',
		flexDirection: 'row',
		width: '100%',
		gap: spacing.sm,
	},
	innerHasProgressStep: {
		borderBottomWidth: PROGRESS_BAR_HEIGHT,
		borderBottomColor: colors.fill_neutral,
	},
	innerNoBorder: {
		borderBottomWidth: 0,
	},
	title: {
		flex: 1,
		maxWidth: '100%',
	},
	titleLink: {
		alignSelf: 'flex-start',
		width: '100%',
	},
	closeContainer: { alignItems: 'center' },
	progressStep: {
		position: 'absolute',
		bottom: -PROGRESS_BAR_HEIGHT,
		left: 0,
		width: '100%',
		borderBottomWidth: PROGRESS_BAR_HEIGHT,
		borderBottomColor: colors.fill_highlight,
	},
}));

export default ModalHeader;
