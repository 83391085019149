import { Path, Svg } from 'react-native-svg';

import { MojoIcon } from '../utils/types';

export default ({ color, size = 24 }: MojoIcon) => (
	<Svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill="none">
		<Path
			d="M3 8.579l2.252-2.25h13.775l2.252 2.25v11.17l-2.252 2.252H5.252L3 19.75V8.579z"
			stroke={color}
			strokeWidth={2.33}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<Path
			d="M4.387 7.188v-3.28L6.295 2h11.676l1.909 1.908v3.28M7.695 14.263l3.066 3.068 5.602-5.6"
			stroke={color}
			strokeWidth={2.33}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</Svg>
);
