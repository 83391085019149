import { createContext, useCallback, useContext, useEffect } from 'react';

export enum InteractionEvent {
	animation_started = 'animation_started',
	animation_completed = 'animation_completed',
	audio_started = 'audio_started',
	audio_progress_update = 'audio_progress_update',
	audio_majority_completed = 'audio_majority_completed',
	audio_completed = 'audio_completed',
	video_started = 'video_started',
	video_progress_update = 'video_progress_update',
	video_majority_completed = 'video_majority_completed',
	video_completed = 'video_completed',
	loading_indicator_persisted = 'loading_indicator_persisted',
	tracking_button_pressed = 'tracking_button_pressed',
}

export type InteractionEventPayload = {
	[InteractionEvent.animation_started]: AnimationInteractionEvent;
	[InteractionEvent.animation_completed]: AnimationInteractionEvent;
	[InteractionEvent.audio_started]: AudioInteractionEvent;
	[InteractionEvent.audio_progress_update]: MediaProgressEvent<AudioInteractionEvent>;
	[InteractionEvent.audio_majority_completed]: AudioInteractionEvent;
	[InteractionEvent.audio_completed]: AudioInteractionEvent;
	[InteractionEvent.video_started]: VideoInteractionEvent;
	[InteractionEvent.video_progress_update]: MediaProgressEvent<VideoInteractionEvent>;
	[InteractionEvent.video_majority_completed]: VideoInteractionEvent;
	[InteractionEvent.video_completed]: VideoInteractionEvent;
	[InteractionEvent.loading_indicator_persisted]: { label: string };
	[InteractionEvent.tracking_button_pressed]: {
		key: string;
	} & Record<string, unknown>; // @TODO: should be able to remove this
};

type BaseEvent = {
	context?: {
		type: string;
	} & Record<string, unknown>;
};

export type AnimationInteractionEvent = BaseEvent & {
	name: string;
};

export type AudioInteractionEvent = BaseEvent & {
	name: string;
};

export type VideoInteractionEvent = BaseEvent & {
	name: string;
	cloudflare_video_id: string;
};

export type MediaProgressEvent<T> = T & {
	progress_bp: number;
};

type InteractionEventListener<T extends InteractionEvent> = (
	payload: InteractionEventPayload[T],
) => void;

type InteractionEventContext = {
	subscriptions: InteractionEventSubscriptions;
};

type InteractionEventSubscriptions = {
	[T in InteractionEvent]?: InteractionEventListener<T>[];
};

export const createInteractionEventsContext = (): InteractionEventContext => {
	return {
		subscriptions: {},
	};
};

export const InteractionEventsContext = createContext(
	createInteractionEventsContext(),
);

export const useInteractionEvents = () => {
	const { subscriptions } = useContext(InteractionEventsContext);

	const on = useCallback(
		<T extends InteractionEvent>(
			event: T,
			listener: InteractionEventListener<T>,
		) => {
			let listeners = subscriptions[event];
			if (!listeners) {
				listeners = subscriptions[event] = [];
			}

			listeners.push(listener);

			return () => {
				if (listeners) {
					const index = listeners.indexOf(listener);
					if (index !== -1) {
						listeners.splice(index, 1);
					}
				}
			};
		},
		[subscriptions],
	);

	const dispatch = useCallback(
		<T extends InteractionEvent>(
			event: T,
			payload: InteractionEventPayload[T],
		) => {
			for (const listener of subscriptions[event] ?? []) {
				listener(payload);
			}
		},
		[subscriptions],
	);

	return {
		on,
		dispatch,
	};
};

// Shortcut to useInteractionEvents().on('event', listener)
export const useInteractionEvent = <T extends InteractionEvent>(
	event: T,
	listener: InteractionEventListener<T>,
) => {
	const { on } = useInteractionEvents();
	useEffect(() => on(event, listener), [on, event, listener]);
};
