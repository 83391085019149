import { Spacing } from '@wearemojo/ui-constants';
import { View } from 'react-native';

import SplitControlPill from './SplitControlPill';
import Stack from './Stack';
import Text from './Text';

type Option<TKey extends string = string> = {
	key: TKey;
	name: string;
	icon?: Parameters<typeof SplitControlPill>[0]['icon'];
};

type Props<TKey extends string = string> = {
	optionColumns?: number;
	optionsTitle: string;
	optionsList: Option<TKey>[];
	selectedOptionKey: TKey;
	onOptionPress: (option: Option<TKey>) => void;
};

const SplitControl = <TKey extends string = string>({
	optionColumns,
	optionsTitle,
	optionsList,
	selectedOptionKey,
	onOptionPress,
}: Props<TKey>) => {
	const rows = !optionColumns
		? [optionsList]
		: new Array(Math.ceil(optionsList.length / optionColumns))
				.fill([])
				.map((_, i) =>
					new Array(optionColumns)
						.fill(undefined)
						.map((_2, j) => optionsList[i * optionColumns + j]),
				);
	return (
		<Stack spacing={Spacing.regular}>
			<Text variant="label_md">{optionsTitle}</Text>
			<Stack spacing={Spacing.regular}>
				{rows.map((row, rowIndex) => (
					<Stack
						key={rowIndex}
						direction="row"
						spacing={Spacing.regular}
						itemFlex
					>
						{row.map((item, itemIndex) =>
							item ? (
								<SplitControlPill
									key={itemIndex}
									selected={selectedOptionKey === item.key}
									label={item.name}
									icon={item.icon}
									onPress={() => onOptionPress(item)}
								/>
							) : (
								<View key={itemIndex} />
							),
						)}
					</Stack>
				))}
			</Stack>
		</Stack>
	);
};

export default SplitControl;
