import { BlockContentType, DayComplete } from '@wearemojo/sanity-schema';
import { SCREEN_MAX_WIDTH_SM } from '@wearemojo/ui-constants';
import { Platform, View } from 'react-native';
import CheckIcon from 'react-native-heroicons/solid/CheckIcon';
import { createStyleSheet, useStyles } from 'react-native-unistyles';

import BackgroundView from '../BackgroundView';
import CMSContent from '../CMSContent';
import ContainedAction from '../ContainedAction';
import Flair from '../Flair';
import Stack from '../Stack';
import Text from '../Text';

type Props = {
	type: DayComplete['type'];
	title: string;
	content: BlockContentType;
	onClosePress: () => void;
};

const HomeDayCompleteView = ({ type, title, content, onClosePress }: Props) => {
	const {
		styles,
		theme: { spacing, colors },
	} = useStyles(stylesheet);

	return (
		<BackgroundView background="background_modal" style={styles.root}>
			<BackgroundView background="background_secondary" style={styles.dialog}>
				<Stack spacing={spacing.lg}>
					<View style={styles.header}>
						<Text variant="heading_xs" style={styles.headerTitleText}>
							{title}
						</Text>
						<ContainedAction
							type="close"
							position="center"
							onPress={onClosePress}
						/>
					</View>
					{type === 'content_without_flair' && (
						<Flair size="medium">
							<View style={styles.iconBox}>
								<CheckIcon
									stroke={colors.content_on_fill}
									fill={colors.content_on_fill}
									size={44}
								/>
							</View>
						</Flair>
					)}
					<CMSContent value={content} />
				</Stack>
			</BackgroundView>
		</BackgroundView>
	);
};

const stylesheet = createStyleSheet(({ radius, spacing }) => ({
	root: {
		justifyContent: 'center',
		alignItems: 'center',
		flex: 1,
	},
	dialog: {
		maxWidth: Platform.select({
			web: SCREEN_MAX_WIDTH_SM,
			default: undefined,
		}),
		borderRadius: radius.sm,
		padding: spacing.md,
		margin: spacing.md,
		gap: spacing.md,
	},
	header: {
		flexDirection: 'row',
		alignItems: 'center',
	},
	headerTitleText: {
		flex: 1,
		marginTop: 2,
	},
	iconBox: {
		alignItems: 'center',
		justifyContent: 'center',
		flex: 1,
	},
}));

export default HomeDayCompleteView;
