import { UIPlatform, UITheme } from '@wearemojo/ui-constants';
import { useContext, useMemo } from 'react';

import UIContext from '../UIContext';
import { UIContext as UIContextType, UIFormFactor } from '../utils/types';

type Context = Omit<UIContextType, 'flags'> & {
	isDark: boolean;
	isWeb: boolean;
	isIos: boolean;
	isAndroid: boolean;
	isNative: boolean;
	isMobile: boolean;
	isDesktop: boolean;
};

const useUIContext = (): Context => {
	const {
		theme,
		platform,
		formFactor,
		reduceTransparency,
		reduceMotion,
		prefersCrossFadeTransitions,
	} = useContext(UIContext);

	return useMemo(
		() => ({
			theme,
			isDark: theme === UITheme.dark,
			platform,
			isWeb: platform === UIPlatform.web,
			isIos: platform === UIPlatform.ios,
			isAndroid: platform === UIPlatform.android,
			isNative: platform !== UIPlatform.web,
			formFactor,
			isMobile: formFactor === UIFormFactor.mobile,
			isDesktop: formFactor === UIFormFactor.desktop,
			reduceTransparency,
			reduceMotion,
			prefersCrossFadeTransitions,
		}),
		[
			theme,
			platform,
			formFactor,
			reduceTransparency,
			reduceMotion,
			prefersCrossFadeTransitions,
		],
	);
};

export default useUIContext;
