import PinCircleIcon from '@wearemojo/icons/Outline/EssentionalUI/PinCircleIcon';
import { ReactNode } from 'react';

import CalloutCard from './CalloutCard';

export type Props = {
	children: ReactNode;
	title?: string;
};

const ConceptCard = ({ children, title }: Props) => {
	return (
		<CalloutCard title={title} icon={PinCircleIcon} type="purple">
			{children}
		</CalloutCard>
	);
};

export default ConceptCard;
