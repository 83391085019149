import imageUrlBuilder from '@sanity/image-url';
import {
	SanityAsset,
	SanityImageObject,
	SanityImageSource,
} from '@sanity/image-url/lib/types/types';
import { useCallback, useContext } from 'react';

import {
	getSanityImageDimensions,
	getSanityImageFormat,
	getSanityImageRefId,
	SanityContext,
} from '../utils/cms';

// avoid recreating imgUrlBuilder every single time
let imageUrlBuilderSingleton: ReturnType<typeof imageUrlBuilder> | undefined;

const useSanityImageAttributes = (imageWidth: number) => {
	const sanityClient = useContext(SanityContext);
	const imgUrlBuilder = sanityClient
		? imageUrlBuilderSingleton
			? imageUrlBuilderSingleton
			: (imageUrlBuilderSingleton = imageUrlBuilder(sanityClient))
		: null;

	return useCallback(
		(value: SanityImageSource) => {
			const uri = imgUrlBuilder
				? imgUrlBuilder.image(value).width(imageWidth).url()
				: undefined;
			const id = getSanityImageRefId(value);
			const dimensions = getSanityImageDimensions(id);
			const format = getSanityImageFormat(id);
			const obj = value as Partial<SanityImageObject>;
			const asset = obj.asset as
				| Partial<SanityAsset & { altText?: string }>
				| undefined;
			const altText =
				asset?.altText?.trim().toLowerCase() === 'decorative'
					? null
					: asset?.altText;
			return {
				uri,
				dimensions,
				format,
				altText,
			};
		},
		[imgUrlBuilder, imageWidth],
	);
};

export default useSanityImageAttributes;
