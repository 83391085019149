import { createContext } from 'react';

import { createUIContext } from './utils/context';
import flags from './utils/flags';

const UIContext = createContext(
	createUIContext({
		flags,
	}),
);

export default UIContext;
