import { borderRadius, Spacing } from '@wearemojo/ui-constants';
import { ReactNode } from 'react';
import { StyleSheet } from 'react-native';

import BackgroundView from '../BackgroundView';
import Stack from '../Stack';
import Text from '../Text';

export type Props = {
	children: ReactNode;
	code: string;
};

const VerificationCodeCard = ({ children, code }: Props) => {
	return (
		<BackgroundView background="background_secondary" style={styles.root}>
			<Stack spacing={Spacing.small}>
				{children}
				<BackgroundView
					background="background_primary"
					style={styles.codeContainer}
				>
					<Text variant="heading_lg" style={styles.code}>
						{code}
					</Text>
				</BackgroundView>
			</Stack>
		</BackgroundView>
	);
};

const styles = StyleSheet.create({
	root: {
		padding: Spacing.large,
		borderRadius,
	},
	codeContainer: {
		padding: Spacing.regular,
		borderRadius,
	},
	code: {
		letterSpacing: 20,
		marginLeft: 20,
		textAlign: 'center',
	},
});

export default VerificationCodeCard;
