import { FragmentMagicLinkApprovalType } from '@wearemojo/sanity-schema';
import { SCREEN_MAX_WIDTH_SM, Spacing } from '@wearemojo/ui-constants';
import { ReactNode } from 'react';
import { SafeAreaView, ScrollView, StyleSheet, View } from 'react-native';

import BackgroundView from '../BackgroundView';
import Button from '../Button';
import VerificationCodeCard from '../cards/VerificationCodeCard';
import Stack from '../Stack';
import Text from '../Text';

export type MagicLinkApprovalFlow =
	| 'asking_approval'
	| 'displaying_code'
	| 'access_revoked';

type Props = {
	navHeader: ReactNode;
	onPressSupport?: () => void;
	magicLinkApprovalFragment: FragmentMagicLinkApprovalType;
	flow: MagicLinkApprovalFlow;
	code: string;
	onApprovePress: () => Promise<void>;
	approving: boolean;
	onRejectPress: () => Promise<void>;
	rejecting: boolean;
};

const MagicLinkApprovalView = ({
	navHeader,
	onPressSupport,
	magicLinkApprovalFragment,
	flow,
	code,
	onApprovePress,
	approving,
	onRejectPress,
	rejecting,
}: Props) => {
	return (
		<SafeAreaView style={styles.safeAreaView}>
			<BackgroundView
				background="background_primary"
				style={StyleSheet.absoluteFillObject}
			/>
			<View style={styles.root}>
				{navHeader}
				<ScrollView>
					<View style={styles.container}>
						{flow === 'asking_approval' && (
							<Stack spacing={Spacing.large}>
								<Text.CMSContent
									variant="heading_sm"
									value={magicLinkApprovalFragment.askingApproval.heading}
								/>
								<Text.CMSContent
									value={magicLinkApprovalFragment.askingApproval.subtitle}
								/>
								<Stack spacing={Spacing.regular}>
									<Button
										title={magicLinkApprovalFragment.askingApproval.approveCta}
										loading={approving}
										onPress={onApprovePress}
									/>
									<Button
										title={magicLinkApprovalFragment.askingApproval.rejectCta}
										type="secondary"
										loading={rejecting}
										onPress={onRejectPress}
									/>
								</Stack>
							</Stack>
						)}
						{flow === 'displaying_code' && (
							<Stack spacing={Spacing.large}>
								<VerificationCodeCard code={code}>
									<Text.CMSContent
										variant="heading_sm"
										value={magicLinkApprovalFragment.displayingCode.heading}
									/>
									<Text.CMSContent
										value={magicLinkApprovalFragment.displayingCode.subtitle}
									/>
								</VerificationCodeCard>
							</Stack>
						)}
						{flow === 'access_revoked' && (
							<Stack spacing={Spacing.large}>
								<Text.CMSContent
									variant="heading_sm"
									value={magicLinkApprovalFragment.accessRevoked.heading}
								/>
								<Text.CMSContent
									value={magicLinkApprovalFragment.accessRevoked.subtitle}
								/>
								<Button
									title={magicLinkApprovalFragment.accessRevoked.supportCta}
									type={'secondary'}
									onPress={onPressSupport}
								/>
							</Stack>
						)}
					</View>
				</ScrollView>
			</View>
		</SafeAreaView>
	);
};

const styles = StyleSheet.create({
	safeAreaView: {
		flex: 1,
	},
	root: {
		flex: 1,
	},
	container: {
		maxWidth: SCREEN_MAX_WIDTH_SM,
		alignSelf: 'center',
		paddingTop: Spacing.extraLarge,
		paddingHorizontal: Spacing.large,
	},
});

export default MagicLinkApprovalView;
