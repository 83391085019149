import {
	Pressable,
	PressableProps,
	PressableStateCallbackType,
	StyleSheet,
} from 'react-native';

import { PressableCallback } from './utils/types';

const PressableOpacity = (props: PressableProps) => {
	const { style } = props;

	if (typeof style === 'function') {
		throw new Error(
			`PressableOpacity style cant be a function. Use Pressable instead.`,
		);
	}

	return (
		<Pressable
			{...props}
			style={
				style == null
					? pressableOpacityStyleFn
					: (state) => [style, ...pressableOpacityStyleFn(state)]
			}
		/>
	);
};

const pressableOpacityStyleFn = (state: PressableStateCallbackType) => {
	const { pressed, hovered } = state as PressableCallback;
	return [hovered && styles.buttonHovered, pressed && styles.buttonPressed];
};

const styles = StyleSheet.create({
	buttonHovered: {
		opacity: 0.8,
	},
	buttonPressed: {
		opacity: 0.6,
	},
});

export default PressableOpacity;
