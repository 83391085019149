import { ReactNode, useContext } from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import Block from '../Block';
import { TextContext, TextPropStyles } from '../Text';
import CMSContext from './CMSContext';

type Props = {
	children: ReactNode;
	style?: StyleProp<ViewStyle>;
	// Text alignment is inherited from root CMS component, but individual blocks can override when desired
	// Setting here will not affect nested CMSBlocks, and mark components may also override
	textAlign?: TextPropStyles['align'];
};

const CMSBlock = ({ children, style, textAlign: scopedAlign }: Props) => {
	const { textAlign: globalAlign } = useContext(CMSContext);
	const align = scopedAlign ?? globalAlign;
	const { spacing } = useContext(CMSContext);
	const inherited = useContext(TextContext);
	return (
		<TextContext.Provider value={{ ...inherited, align }}>
			<Block spacing={spacing} style={style}>
				{children}
			</Block>
		</TextContext.Provider>
	);
};

export default CMSBlock;
