import { Path, Svg } from 'react-native-svg';

import { MojoIcon } from '../utils/types';

export default ({ color, size = 24 }: MojoIcon) => (
	<Svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill="none">
		<Path
			d="M2.85 4.705l2.344-2.343H19.53l2.344 2.343v11.627l-2.344 2.342h-5.237l-2.345 2.333-2.344-2.333H5.192l-2.344-2.342V4.705h.002z"
			stroke={color}
			strokeWidth={2.425}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<Path
			d="M7.612 11.836a1.358 1.358 0 100-2.715 1.358 1.358 0 000 2.715zM12.362 11.836a1.358 1.358 0 100-2.715 1.358 1.358 0 000 2.715zM17.112 11.836a1.358 1.358 0 100-2.715 1.358 1.358 0 000 2.715z"
			fill={color}
		/>
	</Svg>
);
