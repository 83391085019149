import { borderRadius, Spacing } from '@wearemojo/ui-constants';
import { ReactNode } from 'react';
import { StyleSheet } from 'react-native';

import BackgroundView from '../BackgroundView';
import Stack from '../Stack';
import Text from '../Text';

export type Props = {
	children: ReactNode;
	title: string;
	icon: ReactNode;
};

const GenericCard = ({ children, title, icon }: Props) => {
	return (
		<BackgroundView style={styles.root} background="background_secondary">
			<Stack spacing={Spacing.small}>
				<Stack spacing={Spacing.small} direction="row" itemFlex={[false, true]}>
					{icon}
					<Text variant="label_md" weight="bold">
						{title}
					</Text>
				</Stack>
				{children}
			</Stack>
		</BackgroundView>
	);
};

const styles = StyleSheet.create({
	root: {
		padding: Spacing.large,
		borderRadius,
	},
	header: {
		flexDirection: 'row',
		alignItems: 'center',
	},
});

export default GenericCard;
