import { PortableTextComponentProps } from '@portabletext/react';
import { HrType } from '@wearemojo/sanity-schema';

import LineBreak from '../LineBreak';
import CMSBlock from './CMSBlock';

type CMSHrProps = PortableTextComponentProps<HrType>;
const CMSHr = (_: CMSHrProps) => (
	<CMSBlock textAlign="auto">
		<LineBreak />
	</CMSBlock>
);

export default CMSHr;
