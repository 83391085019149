import { PortableTextComponentProps } from '@portabletext/react';
import { EnableNotificationsButtonType } from '@wearemojo/sanity-schema';

import EnableNotificationsButton from '../EnableNotificationsButton';
import CMSBlock from './CMSBlock';

type CMSEnableNotificationsButtonProps =
	PortableTextComponentProps<EnableNotificationsButtonType>;
const CMSEnableNotificationsButton = ({
	value,
}: CMSEnableNotificationsButtonProps) => {
	const { buttonType, buttonTitle, buttonTitleEnabled } = value;

	return (
		<CMSBlock textAlign="auto">
			<EnableNotificationsButton
				type={buttonType}
				title={buttonTitle}
				titleEnabled={buttonTitleEnabled}
			/>
		</CMSBlock>
	);
};

export default CMSEnableNotificationsButton;
