import AtomIcon from '@wearemojo/icons/Outline/Astronomy/AtomIcon';
import { ReactNode } from 'react';

import CalloutCard from './CalloutCard';

export type Props = {
	children: ReactNode;
	title?: string;
};

const ScienceCard = ({ children, title }: Props) => {
	return (
		<CalloutCard title={title} icon={AtomIcon} type="blue">
			{children}
		</CalloutCard>
	);
};

export default ScienceCard;
