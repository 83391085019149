import { useState } from 'react';
import {
	Image as RNImage,
	ImagePropsBase,
	ImageSourcePropType,
	ImageStyle,
	StyleProp,
	StyleSheet,
	View,
} from 'react-native';
import { SvgUri } from 'react-native-svg';

import useUIContext from './hooks/useUIContext';
import ViewRatio from './ViewRatio';

type Props = ImagePropsBase & {
	source: ImageSourcePropType;
	aspectRatio?: number;
	style?: StyleProp<ImageStyle>;
	format?: string;
	onError?: (error: unknown) => void;
	onLoad?: () => void;
};

const Image = ({
	alt,
	source,
	aspectRatio = 1,
	style,
	format,
	...imageProps
}: Props) => {
	const { isWeb } = useUIContext();
	const rootStyles = [styles.root, style];
	const imageStyles = [aspectRatio ? StyleSheet.absoluteFillObject : undefined];
	if (format === 'svg' && !isWeb) {
		const uri = imageSourceUri(source);
		if (typeof uri === 'string') {
			return (
				<ViewRatio aspectRatio={aspectRatio} style={rootStyles}>
					<SvgImage
						uri={uri}
						aspectRatio={aspectRatio}
						style={imageStyles}
						accessible={alt !== undefined}
						accessibilityLabel={alt}
						{...imageProps}
					/>
				</ViewRatio>
			);
		}
	}
	return (
		<ViewRatio aspectRatio={aspectRatio} style={rootStyles}>
			<RNImage
				source={source}
				style={imageStyles}
				accessible={alt !== undefined}
				accessibilityLabel={alt}
				{...imageProps}
			/>
		</ViewRatio>
	);
};

const SvgImage = ({
	uri,
	aspectRatio,
	style,
	...imageProps
}: Omit<Props, 'source'> & { uri: string }) => {
	const [width, setWidth] = useState(0);
	const height = width / (aspectRatio ?? 1);

	return (
		<View onLayout={(event) => setWidth(event.nativeEvent.layout.width)}>
			<SvgUri
				uri={uri}
				width={width}
				height={height}
				style={style}
				{...imageProps}
			/>
		</View>
	);
};

const imageSourceUri = (source: ImageSourcePropType): string | undefined => {
	if (
		typeof source === 'object' &&
		!Array.isArray(source) &&
		typeof source.uri === 'string'
	) {
		return source.uri;
	}
};

const styles = StyleSheet.create({
	root: {
		maxWidth: '100%',
	},
});

export default Image;
