import Paperclip2GradientIcon from '@wearemojo/icons/Bold/MessagesConversation/Paperclip2GradientIcon';
import Pen2Icon from '@wearemojo/icons/Outline/MessagesConversation/Pen2Icon';
import BookIcon from '@wearemojo/icons/Outline/School/BookIcon';
import CalendarIcon from '@wearemojo/icons/Outline/Time/CalendarIcon';
import React, { ElementRef, useEffect, useRef, useState } from 'react';
import { ScrollView, TouchableOpacity, View } from 'react-native';
import { createStyleSheet, useStyles } from 'react-native-unistyles';

import BottomSheet from './BottomSheet';
import ContainedAction from './ContainedAction';
import useUIContext from './hooks/useUIContext';
import Stack from './Stack';
import Text from './Text';

export type ResearchContent = {
	title?: string;
	authors?: string;
	resourceTitle?: string;
	resourceYear?: string;
};

export type Props = {
	title?: string;
	content: ResearchContent[];
};

type ResearchBottomSheetProps = {
	selectedResearch: ResearchContent;
	visible: boolean;
	onClose: () => void;
};

const ResearchCarousel = ({ title, content }: Props) => {
	const { isWeb } = useUIContext();
	const {
		styles,
		theme: { iconSize, spacing },
	} = useStyles(stylesheet);

	const [selectedResearch, setSelectedResearch] =
		useState<ResearchContent | null>(null);
	const [visible, setVisible] = useState(false);

	const openBottomSheet = (research: ResearchContent) => {
		setSelectedResearch(research);
		setVisible(true);
	};

	return (
		<Stack style={styles.root} spacing={spacing.sm}>
			<View style={styles.titleContainer}>
				{!!title && <Text variant="label_md">{title}</Text>}
			</View>
			<View style={styles.researchWrap}>
				<ScrollView horizontal showsHorizontalScrollIndicator={isWeb}>
					<View style={styles.researches}>
						{content && content.length > 0 ? (
							content.map((researchContent, i) => (
								<TouchableOpacity
									key={i}
									style={styles.researchCard}
									onPress={() => openBottomSheet(researchContent)}
								>
									<View style={styles.researchCardInnerContainer}>
										<Paperclip2GradientIcon size={iconSize.md} />
										<Text variant="body_md">
											{researchContent.title &&
											researchContent.title.length > 55
												? researchContent.title.slice(0, 55) + '...'
												: researchContent.title}
										</Text>
									</View>
								</TouchableOpacity>
							))
						) : (
							<View style={styles.noContent}>
								<Text>No content</Text>
							</View>
						)}
					</View>
				</ScrollView>
			</View>
			{selectedResearch && (
				<ResearchBottomSheet
					selectedResearch={selectedResearch}
					visible={visible}
					onClose={() => setVisible(false)}
				/>
			)}
		</Stack>
	);
};

const ResearchBottomSheet = ({
	visible = false,
	selectedResearch,
	onClose,
}: ResearchBottomSheetProps) => {
	const {
		styles,
		theme: { iconSize, colors },
	} = useStyles(bottomSheetStylesheet);

	const bottomSheetRef = useRef<ElementRef<typeof BottomSheet>>(null);

	useEffect(() => {
		if (visible) bottomSheetRef.current?.present();
		if (!visible) bottomSheetRef.current?.dismiss();
	}, [visible]);

	const handleOnClose = () => {
		bottomSheetRef.current?.toggleOverlay();
		onClose?.();
	};

	return (
		<BottomSheet ref={bottomSheetRef} onDismiss={onClose}>
			<View style={styles.radiusSheetContainer}>
				<View style={styles.closeIconContainer}>
					<ContainedAction
						type="close"
						onPress={handleOnClose}
						position="end"
					/>
				</View>
				<View style={styles.contentWrap}>
					<Paperclip2GradientIcon size={iconSize.md} />
					<Text variant="body_lg">{selectedResearch.title}</Text>
					<View style={styles.textContainer}>
						<Pen2Icon size={iconSize.md} color={colors.content_neutral} />
						<Text variant="body_md" themeColor="content_neutral">
							{selectedResearch.authors}
						</Text>
					</View>
					<View style={styles.textContainer}>
						<BookIcon size={iconSize.md} color={colors.content_neutral} />
						<Text variant="body_md" themeColor="content_neutral">
							{selectedResearch.resourceTitle}
						</Text>
					</View>
					<View style={styles.textContainer}>
						<CalendarIcon size={iconSize.md} color={colors.content_neutral} />
						<Text variant="body_md" themeColor="content_neutral">
							{selectedResearch.resourceYear}
						</Text>
					</View>
				</View>
			</View>
		</BottomSheet>
	);
};

const stylesheet = createStyleSheet(({ spacing, colors, radius }) => ({
	root: {
		width: '100%',
	},
	researchWrap: {
		marginHorizontal: -spacing.md,
		marginBottom: -spacing.xs,
	},
	researches: {
		flexDirection: 'row',
		paddingHorizontal: spacing.md,
		paddingBottom: spacing.xs,
	},
	researchCard: {
		backgroundColor: colors.content_neutral700,
		borderRadius: radius.md,
		marginRight: spacing.sm,
		width: 265,
	},
	researchCardInnerContainer: {
		padding: spacing.md,
		gap: spacing.sm,
	},
	noContent: {
		opacity: 0.5,
	},
	titleContainer: {
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
}));

const bottomSheetStylesheet = createStyleSheet(
	({ colors, spacing, iconSize, radius }) => ({
		radiusSheetContainer: {
			borderTopLeftRadius: radius.xl,
			borderTopRightRadius: radius.xl,
			backgroundColor: colors.content_neutral700,
			paddingHorizontal: spacing.xl,
			paddingVertical: spacing.xl,
		},
		closeIconContainer: {
			height: iconSize.lg,
		},
		contentWrap: {
			gap: spacing.md,
		},
		textContainer: {
			flexDirection: 'row',
			alignItems: 'center',
			gap: spacing.sm,
		},
	}),
);

export default ResearchCarousel;
