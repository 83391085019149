import { Spacing } from '@wearemojo/ui-constants';
import { Children, isValidElement, ReactElement, ReactNode } from 'react';
import { StyleSheet, View } from 'react-native';

import BackgroundView from './BackgroundView';
import useUITheme from './hooks/useUITheme';
import TickIcon from './icons/TickIcon';
import Text from './Text';

type Props = {
	children: ReactElement<ItemProps> | ReactElement<ItemProps>[];
	stepsCompleted?: number;
};

type ItemProps = {
	title: string;
	children?: ReactNode;
};

const ProgressTrail = ({ children, stepsCompleted = 0 }: Props) => {
	const totalChildren = Children.toArray(children).filter(Boolean).length;
	const iconColor = useUITheme().background_primary;
	return (
		<View style={styles.root}>
			{Children.map(children, (child, index) => {
				if (!isValidElement(child)) return;
				const isLastItem = index + 1 === totalChildren;
				const isNextItem = index === stepsCompleted;
				const isCompleted = index < stepsCompleted;
				return (
					<View
						style={[styles.item, isLastItem && styles.itemLastChild]}
						key={index}
					>
						{!isLastItem && (
							<BackgroundView
								background={isCompleted ? 'fill_highlight' : 'fill_neutral'}
								style={styles.itemTrail}
							/>
						)}
						<BackgroundView
							background={
								isCompleted || isNextItem ? 'fill_highlight' : 'fill_neutral'
							}
							style={styles.itemTrailIndicator}
						/>
						{isCompleted && (
							<View style={styles.itemTrailIndicatorTick}>
								<TickIcon fill={iconColor} />
							</View>
						)}
						{isNextItem && (
							<View
								style={[
									styles.itemTrailIndicatorDot,
									{ backgroundColor: iconColor },
								]}
							/>
						)}
						<View>
							<Text variant="heading_xs" strikethrough={isCompleted}>
								{child.props.title}
							</Text>
						</View>
						{children && <View>{child.props.children}</View>}
					</View>
				);
			})}
		</View>
	);
};

const ProgressTrailItem = (_: ItemProps) => null;

ProgressTrail.Item = ProgressTrailItem;

const INDICATOR_SIZE = 22;
const INDICATOR_TICK_SIZE = 12;
const INDICATOR_DOT_SIZE = 10;
const TRAIL_WIDTH = 8;

const styles = StyleSheet.create({
	root: {},
	item: {
		paddingLeft: Spacing.dsDeviation__24 + INDICATOR_SIZE,
		paddingBottom: Spacing.extraLarge,
	},
	itemLastChild: {
		paddingBottom: 0,
	},
	itemTrail: {
		position: 'absolute',
		left: INDICATOR_SIZE / 2 - TRAIL_WIDTH / 2,
		top: INDICATOR_SIZE / 2,
		bottom: -INDICATOR_SIZE / 2, // border radius on indicator leaves a gap
		width: TRAIL_WIDTH,
	},
	itemTrailIndicator: {
		position: 'absolute',
		left: 0,
		top: 0,
		width: INDICATOR_SIZE,
		height: INDICATOR_SIZE,
		borderRadius: INDICATOR_SIZE / 2,
	},
	itemTrailIndicatorTick: {
		width: INDICATOR_SIZE,
		height: INDICATOR_SIZE,
		padding: (INDICATOR_SIZE - INDICATOR_TICK_SIZE) / 2,
		position: 'absolute',
		left: 0,
		top: 0,
		alignItems: 'center',
		justifyContent: 'center',
	},
	itemTrailIndicatorDot: {
		position: 'absolute',
		left: INDICATOR_SIZE / 2 - INDICATOR_DOT_SIZE / 2,
		top: INDICATOR_SIZE / 2 - INDICATOR_DOT_SIZE / 2,
		width: INDICATOR_DOT_SIZE,
		height: INDICATOR_DOT_SIZE,
		borderRadius: INDICATOR_DOT_SIZE / 2,
	},
});

export default ProgressTrail;
