import { Line, Svg } from 'react-native-svg';

import useUITheme from '../hooks/useUITheme';

export default ({
	fill: _fill,
	strokeWidth = 4,
}: {
	fill?: string;
	strokeWidth?: number;
}) => {
	const defaultFill = useUITheme().border_primary;
	const fill = _fill ?? defaultFill;
	return (
		<Svg width="164" height="70" viewBox="0 0 164 70" fill="none">
			<Line
				x1="2"
				y1="21"
				x2="2"
				y2="49"
				stroke={fill}
				strokeWidth={strokeWidth}
			/>
			<Line
				x1="10"
				y1="24.5"
				x2="10"
				y2="45.5"
				stroke={fill}
				strokeWidth={strokeWidth}
			/>
			<Line
				x1="18"
				y1="21.5"
				x2="18"
				y2="48.5"
				stroke={fill}
				strokeWidth={strokeWidth}
			/>
			<Line
				x1="26"
				y1="11"
				x2="26"
				y2="59"
				stroke={fill}
				strokeWidth={strokeWidth}
			/>
			<Line
				x1="34"
				y1="5"
				x2="34"
				y2="65"
				stroke={fill}
				strokeWidth={strokeWidth}
			/>
			<Line
				x1="42"
				y1="1"
				x2="42"
				y2="69"
				stroke={fill}
				strokeWidth={strokeWidth}
			/>
			<Line
				x1="50"
				y1="11"
				x2="50"
				y2="59"
				stroke={fill}
				strokeWidth={strokeWidth}
			/>
			<Line
				x1="58"
				y1="17"
				x2="58"
				y2="53"
				stroke={fill}
				strokeWidth={strokeWidth}
			/>
			<Line
				x1="66"
				y1="31"
				x2="66"
				y2="39"
				stroke={fill}
				strokeWidth={strokeWidth}
			/>
			<Line
				x1="74"
				y1="27"
				x2="74"
				y2="43"
				stroke={fill}
				strokeWidth={strokeWidth}
			/>
			<Line
				x1="82"
				y1="21"
				x2="82"
				y2="49"
				stroke={fill}
				strokeWidth={strokeWidth}
			/>
			<Line
				x1="90"
				y1="25"
				x2="90"
				y2="45"
				stroke={fill}
				strokeWidth={strokeWidth}
			/>
			<Line
				x1="98"
				y1="21"
				x2="98"
				y2="49"
				stroke={fill}
				strokeWidth={strokeWidth}
			/>
			<Line
				x1="106"
				y1="10"
				x2="106"
				y2="60"
				stroke={fill}
				strokeWidth={strokeWidth}
			/>
			<Line
				x1="114"
				y1="4"
				x2="114"
				y2="66"
				stroke={fill}
				strokeWidth={strokeWidth}
			/>
			<Line
				x1="122"
				y1="-8.74228e-08"
				x2="122"
				y2="70"
				stroke={fill}
				strokeWidth={strokeWidth}
			/>
			<Line
				x1="130"
				y1="11"
				x2="130"
				y2="59"
				stroke={fill}
				strokeWidth={strokeWidth}
			/>
			<Line
				x1="138"
				y1="18"
				x2="138"
				y2="52"
				stroke={fill}
				strokeWidth={strokeWidth}
			/>
			<Line
				x1="146"
				y1="32"
				x2="146"
				y2="38"
				stroke={fill}
				strokeWidth={strokeWidth}
			/>
			<Line
				x1="154"
				y1="26"
				x2="154"
				y2="44"
				stroke={fill}
				strokeWidth={strokeWidth}
			/>
			<Line
				x1="162"
				y1="20"
				x2="162"
				y2="50"
				stroke={fill}
				strokeWidth={strokeWidth}
			/>
		</Svg>
	);
};
