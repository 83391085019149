import { getProperty } from 'dot-prop';
import { isValidElement, ReactNode, useContext } from 'react';
import { StyleSheet } from 'react-native';

import Text from '../Text';
import { withErrorBoundary } from '../utils/errors';
import { monospaceFont } from '../utils/typography';
import CMSContext from './CMSContext';

const modifyNode = (node: ReactNode, replacementText: string): ReactNode => {
	if (typeof node === 'string') {
		return replacementText;
	}

	if (Array.isArray(node)) {
		return node.map((x) => modifyNode(x, replacementText));
	}

	if (isValidElement(node)) {
		return {
			...node,
			props: {
				...node.props,
				children: modifyNode(node.props.children, replacementText),
			},
		};
	}

	return node;
};

const CMSContentVariable = ({
	path,
	children,
}: {
	path: string;
	children: ReactNode;
}) => {
	const { contentVariables = {} } = useContext(CMSContext);

	const value = getProperty(contentVariables, path);
	if (value === undefined) {
		console.error(`Content variable path "${path}" not found`);
		return (
			<Text style={styles.monospace}>
				{'{'}
				{path}
				{'}'}
			</Text>
		);
	}

	return <Text>{modifyNode(children, value)}</Text>;
};

const styles = StyleSheet.create({
	monospace: {
		fontFamily: monospaceFont,
		textTransform: 'none',
		color: 'rgba(127,127,127,0.5)',
	},
});

export default withErrorBoundary(CMSContentVariable);
