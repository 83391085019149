import {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
} from 'react';

import { ScreenEvent, useScreenEvent } from './screenEvents';
import { WaveformData } from './waveform';

type InstanceId = string;
export type AudioPlayerProgress = {
	positionMs: number;
	durationMs: number;
};

export type AudioPlayerInstanceId = InstanceId;

export type AudioMetadata = {
	title?: string;
	artist?: string;
	artwork?: string;
};

export enum AudioPlayerEvent {
	status = 'status',
	progress = 'progress',
	meta = 'meta',
}

export type AudioPlayerEventPayload = {
	[AudioPlayerEvent.status]: AudioPlayerStatus;
	[AudioPlayerEvent.meta]: AudioPlayerMeta;
	[AudioPlayerEvent.progress]: AudioPlayerProgress;
};

// For providing platform specific implementations of audio player (to be passed down via provider)
export type AudioPlayer = {
	attach(id: InstanceId): Readonly<AudioPlayerInstance>; // attaches to existing instance (via id), or creates a new one
	release(id: InstanceId): void; // marks instance as released from current screen/component/context
	destroy(id: InstanceId, force?: boolean): void; // destroys instance (force required if attachCount > 0)
};

// API provided to components via useAudioPlayer (shared across platforms), provided by implementations
export type AudioPlayerInstance = {
	preload(): void;
	play(): void;
	pause(): void;
	stop(): void;
	getMeta(): AudioPlayerMeta;
	getStatus(): AudioPlayerStatus;
	getProgress(): AudioPlayerProgress;
	setProgress(progress: Partial<AudioPlayerProgress>): void;
	jumpPositionTo(sec: number): void;
	jumpPositionBy(sec: number): void;
	setSource(source: AudioSource): void;
	setMetadata(metadata: AudioMetadata): void;
	setPlaybackRate(rate: number): void;
	on<
		Event extends AudioPlayerEvent,
		Payload extends AudioPlayerEventPayload[Event],
	>(
		event: Event,
		callback: (payload: Payload) => void,
	): void;
	hooks: AudioPlayerInstanceHooks;
};

// Hooks for use with AudioPlayerInstance
export type AudioPlayerInstanceHooks = {
	useMeta: () => AudioPlayerMeta;
	useStatus: () => AudioPlayerStatus;
	useProgress: () => AudioPlayerProgress;
};

export type AudioPlayerMeta = {
	source: AudioSource;
	metadata: AudioMetadata;
	// Primarily for implementation use
	instanceId: InstanceId;
	isActiveInstance: boolean; // only one instance is active (and allows playback) at a time
	attachCount: number; // increments when attached to, decrements when detached
};

export type AudioSource = { uri: string } | undefined;

export type AudioPlayerStatus = {
	isLoaded: boolean;
	isLoading: boolean;
	isPlaying: boolean;
	isLooping: boolean;
	playbackRate: PlaybackRateType;
	error?: string;
};

export type AudioPlayerOptions = {
	instanceId?: InstanceId; // allows (re-)attaching to an existing instance
	onNavigateIn?: undefined | 'play'; // action to trigger when navigating into screen with instance embedded (attach will always occur)
	onNavigateOut?: undefined | 'pause' | 'stop' | 'destroy'; // action to trigger when navigating out of screen (release will always occur)
	preload?: boolean; // whether to preload source
};

export const PLAYBACK_RATES = [0.75, 1, 1.25, 1.5, 1.75, 2] as const;

export type PlaybackRateType = (typeof PLAYBACK_RATES)[number];

export type AudioPlayerChildrenProps = {
	playbackRate: PlaybackRateType;
	maximumValue: number;
	currentTimeSec: number;
	onValueChange: (seconds: number) => void;
	onSlidingComplete: (seconds: number) => void;
	onSlidingStart: () => void;
	totalTimeSec: number;
	setPlaybackRate: (seconds: PlaybackRateType) => void;
	positionMsRef: React.MutableRefObject<number>;
	hasError: boolean;
	error?: string;
	status: AudioPlayerStatus;
	slider: JSX.Element;
	playControl: JSX.Element;
	durationMs: number;
	showWaveform?: boolean;
	waveformData?: WaveformData;
	waveformColor?: string;
	jumpPositionBy?: (seconds: number) => void;
	audioImage?: {
		uri: string | undefined;
	};
};

const noop = () => {
	throw new Error('AudioPlayer implementation is missing');
};

export const AudioPlayerContext = createContext<AudioPlayer>({
	attach: noop,
	release: noop,
	destroy: noop,
});

export const useAudioPlayer = (
	source: AudioSource | undefined,
	{
		instanceId: existingInstanceId,
		onNavigateIn,
		onNavigateOut,
		preload = true,
	}: AudioPlayerOptions = {},
): AudioPlayerInstance => {
	const audioPlayer = useContext(AudioPlayerContext);
	const instanceIdRef = useRef(existingInstanceId ?? createInstanceId());
	const instanceId = instanceIdRef.current;
	const instance = useAudioPlayerInstance(instanceId);
	const navigateIn = useCallback(() => {
		if (onNavigateIn) {
			instance[onNavigateIn]();
		}
	}, [onNavigateIn, instance]);
	useScreenEvent(ScreenEvent.navigateIn, navigateIn);

	console.log({
		instanceId,
		source,
		preload,
		onNavigateOut,
		onNavigateIn,
		instanceIdRef,
	});

	const navigateOut = useCallback(() => {
		if (onNavigateOut) {
			switch (onNavigateOut) {
				case 'destroy':
					audioPlayer.destroy(instanceId);
					break;
				default:
					instance[onNavigateOut]?.();
			}
		}
	}, [instanceId, audioPlayer, onNavigateOut, instance]);
	useScreenEvent(ScreenEvent.navigateOut, navigateOut);

	useEffect(() => {
		if (source) {
			instance.setSource(source);
			if (
				preload &&
				instance.getMeta().attachCount === 1 &&
				!instance.getStatus().isLoaded
			) {
				instance.preload();
			}
		}
	}, [instance, source, preload]);

	return instance;
	// @TODO cleanly handle destroy (change instanceId? or maybe better to give undefined and let components properly handle it?)
};

const useAudioPlayerInstance = (
	instanceId: InstanceId,
): AudioPlayerInstance => {
	const audioPlayer = useContext(AudioPlayerContext);

	// Attach and release (updates attachCount)
	const instance = useMemo(
		() => audioPlayer.attach(instanceId),
		[audioPlayer, instanceId],
	);
	useEffect(() => {
		return () => audioPlayer.release(instanceId);
	}, [audioPlayer, instanceId]);

	return instance;
};

const createInstanceId = (): InstanceId =>
	Math.random().toString(36).substring(4, 15);

export const formatTime = (seconds: number) => {
	if (isNaN(seconds)) {
		return '0:00';
	}
	const mins = Math.floor(seconds / 60);
	const sec = seconds - mins * 60;
	return `${mins}:${String(sec).padStart(2, '0')}`;
};
