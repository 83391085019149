import { buttonWebDataSelector } from '../Button';
import { textMaskWebDataSelector } from '../TextMask.web';

export const textInputWebDataSelector = 'mojo-ui-textinput';

const globalStyle = `
	:root {
		color-scheme: dark;
	}

	html {
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-rendering: optimizeLegibility;
		text-underline-position: from-font;
		text-decoration-thickness: from-font;
		font-family: sans-serif;
	}

	[data-${buttonWebDataSelector}] { outline-offset: 2px; }
	[data-${textInputWebDataSelector}] { outline: 0 none; }
	[data-${textMaskWebDataSelector}], [data-${textMaskWebDataSelector}] * {
		background-clip: text;
		-webkit-background-clip: text;
		text-fill-color: transparent;
		-webkit-text-fill-color: transparent;
	}

	[aria-disabled=true] { cursor: not-allowed; }

	/* https://github.com/facebook/create-react-app/issues/11773 */
	body > iframe[style*="position: fixed"][style*="z-index: 2147483647"] {
		/* react-error-overlay blocks entire viewport on (handled) exceptions, reduce to red dot until fixed */
		width: 10px !important;
		height: 10px !important;
		left: auto !important;
		right: 0 !important;
		border-radius: 100%;
		background-color: red;
		margin: 5px;
	}
`;

const globalStyleId = '__globalStyle__';
function getStyleElement() {
	const element = document.getElementById(globalStyleId);
	if (element && element) {
		return element;
	}
	const styleElement = document.createElement('style');
	styleElement.id = globalStyleId;
	styleElement.type = 'text/css';
	return styleElement;
}

export function injectGlobalStyles() {
	const styleElement = getStyleElement();
	const textNode = document.createTextNode(globalStyle);
	styleElement.appendChild(textNode);
	document.head!.appendChild(styleElement);
}
