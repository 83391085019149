import { InlineContentWrapperType } from '@wearemojo/sanity-schema';
import { Image } from 'expo-image';
import { createStyleSheet, useStyles } from 'react-native-unistyles';

import BackgroundView from '../BackgroundView';
import Stack from '../Stack';
import Text from '../Text';

type Props = {
	content: InlineContentWrapperType;
	author: string;
	authorImage?: {
		uri?: string;
		altText?: string | null;
	};
};

const QuoteCard = ({ content, author, authorImage }: Props) => {
	const { styles, theme } = useStyles(stylesheet);
	return (
		<BackgroundView background="background_level_1" style={styles.container}>
			<Stack spacing={theme.spacing.md}>
				{authorImage && authorImage.uri && (
					<Image
						source={authorImage}
						style={styles.authorImage}
						alt={authorImage.altText || ''}
					/>
				)}
				<Text.CMSContent value={content} variant="body_lg" />
				<Text variant="heading_xxs_upper" themeColor="content_neutral">
					{author}
				</Text>
			</Stack>
		</BackgroundView>
	);
};

const IMAGE_SIZE = 70;

const stylesheet = createStyleSheet(({ spacing, radius }) => ({
	container: {
		padding: spacing.xl,
		borderRadius: radius.md,
	},
	authorImage: {
		width: IMAGE_SIZE,
		height: IMAGE_SIZE,
		borderRadius: IMAGE_SIZE / 2,
	},
}));

export default QuoteCard;
