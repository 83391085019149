import {
	borderRadius,
	Spacing,
	themeColors,
	UITheme,
} from '@wearemojo/ui-constants';
import { Dispatch, SetStateAction } from 'react';
import { Modal, Pressable, StyleSheet, View } from 'react-native';

import BackgroundView from './BackgroundView';
import InvertTheme from './InvertTheme';
import KeyboardAvoidingView from './KeyboardAvoidingView';
import ModalHeader from './ModalHeader';
import ModalNav from './ModalNav';
import RootView from './RootView';
import Stack from './Stack';
import Text from './Text';

export type Props = {
	controls: Array<React.ReactNode>;
	children: React.ReactNode;
	isExpanded: boolean;
	setIsExpanded: Dispatch<SetStateAction<boolean>>;
};

const StaffKnob = ({
	controls,
	children,
	isExpanded,
	setIsExpanded,
}: Props) => {
	return (
		<RootView.WithSafeArea edges={['bottom']} style={styles.root}>
			<View style={styles.knob}>
				<Pressable
					style={styles.trigger}
					onPress={() => setIsExpanded((v) => !v)}
				>
					<Text style={styles.text} variant="heading_xs">
						🍆
					</Text>
				</Pressable>
			</View>
			<Modal transparent animationType="fade" visible={isExpanded}>
				<InvertTheme force={UITheme.dark}>
					<View style={styles.modal}>
						<ModalHeader
							title="Staff Knob&nbsp;&nbsp;🍆"
							onPressClose={() => setIsExpanded(false)}
							transparent
						/>
						<ModalNav.View>
							<KeyboardAvoidingView.ViaPadding insideSafeArea>
								<BackgroundView
									background="fill_highlight"
									style={styles.content}
								>
									{children}
								</BackgroundView>
								{controls.map((controlSet, i) => (
									<View key={i} style={[styles.controlSet]}>
										<Stack spacing={Spacing.regular}>{controlSet}</Stack>
									</View>
								))}
							</KeyboardAvoidingView.ViaPadding>
						</ModalNav.View>
					</View>
				</InvertTheme>
			</Modal>
		</RootView.WithSafeArea>
	);
};

type DevControlProps = {
	title: string;
	onPress?: () => void;
	icon?: string;
};

const DevControl = ({ title, onPress, icon }: DevControlProps) => (
	<Pressable style={styles.control} onPress={onPress}>
		<View style={styles.controlIcon}>
			<Text>{icon}</Text>
		</View>
		<Text style={styles.text} weight="bold">
			{title}
		</Text>
	</Pressable>
);

const styles = StyleSheet.create({
	root: {
		position: 'absolute',
		opacity: 0.85,
		bottom: 8,
		left: 0,
		width: 55,
		backgroundColor: 'transparent',
	},
	knob: {
		paddingVertical: Spacing.regular,
		paddingHorizontal: Spacing.extraSmall,
		alignItems: 'center',
	},
	trigger: {
		alignItems: 'center',
		justifyContent: 'center',
		padding: Spacing.extraSmall,
	},
	text: {
		color: themeColors[UITheme.dark].content_on_dark,
	},
	modal: {
		flex: 1,
		backgroundColor: 'rgba(0,0,0,0.9)',
		padding: Spacing.extraLarge,
	},
	controlSet: {
		paddingTop: Spacing.extraLarge,
		marginTop: Spacing.extraLarge,
		borderTopWidth: 1,
		borderTopColor: themeColors[UITheme.dark].border_neutral,
	},
	control: {
		borderRadius: 9999,
		paddingVertical: Spacing.extraSmall,
		paddingHorizontal: Spacing.regular,
		borderWidth: 1,
		borderColor: themeColors[UITheme.dark].border_neutral,
		flexDirection: 'row',
	},
	controlIcon: {
		marginRight: Spacing.small,
	},
	content: {
		backgroundColor: themeColors[UITheme.dark].fill_highlight_darker,
		borderRadius,
		padding: Spacing.regular,
	},
});

StaffKnob.Control = DevControl;

export default StaffKnob;
