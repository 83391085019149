import { ColorValue } from 'react-native';
import { Path, Svg } from 'react-native-svg';

export default ({ fill }: { fill: ColorValue }) => (
	<Svg viewBox="0 0 25 29">
		<Path
			d="M23.8445 12.7692C25.1749 13.5396 25.1749 15.4604 23.8445 16.2308L3.66373 27.9169C2.3304 28.689 0.661498 27.7269 0.661498 26.1862L0.661499 2.81383C0.661499 1.27308 2.3304 0.310974 3.66373 1.08307L23.8445 12.7692Z"
			fill={fill}
		/>
	</Svg>
);
