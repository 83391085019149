import TileWithExplanation, {
	TileWithExplanationProps,
} from '@wearemojo/ui-components/src/TileWithExplanation';
import { Spacing } from '@wearemojo/ui-constants';
import React from 'react';
import { StyleSheet, View } from 'react-native';

import LineBreak from './LineBreak';
import Stack from './Stack';

type Props = {
	items: TileWithExplanationProps[];
};

const TileWithExplanationList = ({ items }: Props) => (
	<Stack spacing={Spacing.regular}>
		{items.map((item, index) => (
			<View key={index}>
				<TileWithExplanation
					image={item.image}
					imageAlt={item.imageAlt}
					title={item.title}
					content={item.content}
				/>
				{items.length > 1 && (
					<View style={styles.lineBreak}>
						<LineBreak />
					</View>
				)}
			</View>
		))}
	</Stack>
);

const styles = StyleSheet.create({
	lineBreak: {
		marginTop: Spacing.small,
		maxWidth: 640,
	},
});

export default TileWithExplanationList;
