import { themeColors, UITheme } from '@wearemojo/ui-constants';
import { useContext } from 'react';

import UIContext from '../UIContext';

const useUITheme = (theme?: UITheme) => {
	const { theme: currentTheme } = useContext(UIContext);
	return themeColors[theme ?? currentTheme];
};

export default useUITheme;
