import { BrandColor } from '@wearemojo/ui-constants';
import { Image, ImageProps } from 'expo-image';
import { View } from 'react-native';
import { createStyleSheet, useStyles } from 'react-native-unistyles';

import ShieldCheckGradientIcon from './icons/ShieldCheckGradientIcon';
import PressableOpacity from './PressableOpacity';
import Stack from './Stack';
import Text from './Text';
import TextMask from './TextMask';

type Props = {
	headline: string;
	name: string;
	imageUrl: ImageProps['source'];
	isVerified?: boolean;
	onPress?: () => void;
};

const ExpertChip = ({
	headline,
	name,
	imageUrl,
	isVerified,
	onPress,
}: Props) => {
	const {
		styles,
		theme: { spacing },
	} = useStyles(stylesheet);
	return (
		<PressableOpacity onPress={onPress}>
			<Stack direction="row" spacing={spacing.xs}>
				<View style={styles.imageContainer}>
					<Image source={imageUrl} style={styles.image} />
					{isVerified && (
						<View style={styles.iconContainer}>
							<ShieldCheckGradientIcon size={ICON_SIZE} />
						</View>
					)}
				</View>
				<View style={styles.textContainer}>
					<TextMask
						colors={[
							BrandColor.primary_blue_lighter,
							BrandColor.primary_yellow_lighter,
						]}
						startPoint={[0, 0]}
						endPoint={[1, 0]}
						variant="heading_xxs_upper"
					>
						{headline}
					</TextMask>
					<Text themeColor="content_neutral" variant="heading_xxs_upper">
						{name}
					</Text>
				</View>
			</Stack>
		</PressableOpacity>
	);
};

const IMAGE_SIZE = 32;
const ICON_SIZE = 14;

const stylesheet = createStyleSheet(() => ({
	imageContainer: {
		position: 'relative',
	},
	image: {
		width: IMAGE_SIZE,
		height: IMAGE_SIZE,
		borderRadius: IMAGE_SIZE / 2,
	},
	iconContainer: {
		position: 'absolute',
		bottom: -1,
		right: -1,
	},
	textContainer: {
		flex: 1,
		justifyContent: 'center',
	},
}));

export default ExpertChip;
