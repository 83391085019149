import { getLinearGradientBackgroundImage } from 'expo-linear-gradient/src/NativeLinearGradient.web';
import { PropsWithChildren, useMemo, useState } from 'react';
import { TextProps } from 'react-native';

import Text, { TextPropStyles } from './Text';

type Props = PropsWithChildren<
	TextProps &
		TextPropStyles & {
			colors: string[];
			locations?: number[];
			startPoint?: [number, number];
			endPoint?: [number, number];
		}
>;

export const textMaskWebDataSelector = 'mojo-ui-textmask';

const dataAttrs = {
	dataSet: {
		[textMaskWebDataSelector]: '', // Used as hook for CSS selector on web
	},
};

const TextMask = ({
	children,
	colors,
	locations,
	startPoint,
	endPoint,
	...textProps
}: Props) => {
	const [gradientSize, setLayout] = useState({
		height: 1,
		width: 1,
	});

	const linearGradientBackgroundImage = useMemo(() => {
		return getLinearGradientBackgroundImage(
			colors,
			locations,
			startPoint,
			endPoint,
			gradientSize.width,
			gradientSize.height,
		);
	}, [
		colors,
		locations,
		startPoint,
		endPoint,
		gradientSize.width,
		gradientSize.height,
	]);

	return (
		<Text
			style={{
				// @ts-ignore
				backgroundImage: linearGradientBackgroundImage,
			}}
			{...dataAttrs}
			{...textProps}
			onLayout={(event) => {
				// Based on code in expo-linear-gradient/src/NativeLinearGradient.web.tsx
				const { width, height } = event.nativeEvent.layout;
				setLayout((oldLayout) => {
					if (width !== oldLayout.width || height !== oldLayout.height) {
						return { height, width };
					}

					return oldLayout;
				});

				textProps.onLayout?.(event);
			}}
		>
			{children}
		</Text>
	);
};

export default TextMask;
