import { PortableTextComponentProps } from '@portabletext/react';

import ErrorBox from '../ErrorBox';
import CMSBlock from './CMSBlock';

type CMSReferenceProps = PortableTextComponentProps<undefined>;
const CMSReference = ({ value }: CMSReferenceProps) => {
	const label = 'Unresolved CMS Reference';
	const message = JSON.stringify(value);
	console.warn(label, message);
	return (
		<CMSBlock>
			<ErrorBox level="warning" label={label} message={message} />
		</CMSBlock>
	);
};

export default CMSReference;
