import { createContext, useContext } from 'react';

type FlowConfig = {
	// TODO soon transparent header might be the norm for all, so we should remove this
	hasTransparentHeader?: boolean;
};

export const FlowConfigContext = createContext<FlowConfig>({});

export const useFlowConfig = () => {
	return useContext(FlowConfigContext);
};
