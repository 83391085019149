import { PortableTextComponentProps } from '@portabletext/react';
import { ExpertChipType } from '@wearemojo/sanity-schema';
import { createStyleSheet, useStyles } from 'react-native-unistyles';

import ExpertChip from '../ExpertChip';
import { useCMSGlobalContext } from '../utils/cms';
import CMSBlock from './CMSBlock';

type CMSExpertChipProps = PortableTextComponentProps<ExpertChipType>;
const CMSExpertChip = ({
	value: { headline, author, authorImage, isVerified },
}: CMSExpertChipProps) => {
	const { imgAttr } = useCMSGlobalContext();
	let uri;
	if (authorImage) {
		uri = imgAttr(authorImage).uri;
	}

	const { styles } = useStyles(stylesheet);

	return (
		<CMSBlock textAlign="left" style={styles.root}>
			<ExpertChip
				headline={headline}
				name={author}
				imageUrl={{ uri }}
				isVerified={isVerified}
			/>
		</CMSBlock>
	);
};

const stylesheet = createStyleSheet(({ spacing }) => ({
	root: {
		paddingVertical: spacing.xs,
		alignItems: 'center',
	},
}));

export default CMSExpertChip;
