import { PortableTextComponentProps } from '@portabletext/react';
import { TileWithExplanationListType } from '@wearemojo/sanity-schema';

import TileWithExplanationList from '../TileWithExplanationList';
import CMSBlock from './CMSBlock';

type CMSTileWithExplanationListProps =
	PortableTextComponentProps<TileWithExplanationListType>;

const CMSTileWithExplanationList = ({
	value: { items },
}: CMSTileWithExplanationListProps) => {
	const tileItems = items.map((item) => ({
		...item,
		image: { uri: item.image.asset.url },
	}));
	return (
		<CMSBlock textAlign="auto">
			<TileWithExplanationList items={tileItems} />
		</CMSBlock>
	);
};

export default CMSTileWithExplanationList;
