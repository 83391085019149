import { InlineContentType } from '@wearemojo/sanity-schema';
import { Spacing } from '@wearemojo/ui-constants';

import PopupInfo from '../PopupInfo';
import Stack from '../Stack';
import Text from '../Text';
import TextAction from '../TextAction';

type Props = {
	title: string;
	content: InlineContentType;
	contactSupportCtaText: string;
	controls?: React.ReactNode;
	onSupportPress: () => void;
	onClosePress: () => void;
};

const DeleteAccountView = ({
	title,
	content,
	controls,
	onClosePress,
	contactSupportCtaText,
	onSupportPress,
}: Props) => {
	return (
		<PopupInfo title={title} onClosePress={onClosePress}>
			<Stack spacing={Spacing.large}>
				<Text.CMSContent value={content} />
				<Stack spacing={Spacing.small}>
					{controls}
					<TextAction
						title={contactSupportCtaText}
						position="center"
						onPress={onSupportPress}
					/>
				</Stack>
			</Stack>
		</PopupInfo>
	);
};

export default DeleteAccountView;
