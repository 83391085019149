import { PortableTextComponentProps } from '@portabletext/react';
import { CarouselType } from '@wearemojo/sanity-schema';
import { useMemo } from 'react';

import TileCardCarousel, { TileCardProps } from '../TileCardCarousel';
import { useCMSGlobalContext } from '../utils/cms';
import CMSBlock from './CMSBlock';

type CMSCarouselProps = PortableTextComponentProps<CarouselType>;
const CMSCarousel = ({ value }: CMSCarouselProps) => {
	const { imgAttr, transformActionToActionLink } = useCMSGlobalContext();
	const { variant, tileSize, tileAspectRatio, tiles } = value;

	const content: TileCardProps[] = useMemo(
		() =>
			tiles.map((tile) => {
				const image = imgAttr(tile.image);
				const action = tile.action?.[0];
				const linkProvider = action
					? transformActionToActionLink(action)?.linkProvider
					: undefined;
				return {
					...tile,
					linkProvider,
					image,
					imageFormat: image.format,
					variant,
				} as TileCardProps;
			}),
		[tiles, imgAttr, variant, transformActionToActionLink],
	);

	return (
		<CMSBlock textAlign="auto">
			<TileCardCarousel
				tileSize={tileSize}
				tileAspectRatio={tileAspectRatio}
				content={content}
			/>
		</CMSBlock>
	);
};

export default CMSCarousel;
