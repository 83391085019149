import LockOpenIcon from '@wearemojo/icons/Outline/Security/LockKeyholeMinimalisticUnlockedIcon';
import { BlockContentType } from '@wearemojo/sanity-schema';
import { SCREEN_MAX_WIDTH_SM } from '@wearemojo/ui-constants';
import { Platform, ScrollView, useWindowDimensions, View } from 'react-native';
import { createStyleSheet, useStyles } from 'react-native-unistyles';

import BackgroundView from '../BackgroundView';
import Button from '../Button';
import CMSContent from '../CMSContent';
import ContainedAction from '../ContainedAction';
import Flair from '../Flair';
import Stack from '../Stack';
import Text from '../Text';

type Props = {
	title: string;
	content: BlockContentType;
	ctaText: string;
	onCtaPress: () => void;
	onClosePress: () => void;
};

const FreeUserFirstCourseCompleteView = ({
	title,
	content,
	ctaText,
	onCtaPress,
	onClosePress,
}: Props) => {
	const {
		styles,
		theme: { spacing, colors },
	} = useStyles(stylesheet);
	const { height } = useWindowDimensions();

	return (
		<BackgroundView background="background_modal" style={styles.root}>
			<BackgroundView background="background_secondary" style={styles.dialog}>
				<Stack spacing={spacing.lg}>
					<View style={styles.header}>
						<Text variant="heading_xs" style={styles.headerTitleText}>
							{title}
						</Text>
						<View style={styles.containedActionContainer}>
							<ContainedAction onPress={onClosePress} type="close" />
						</View>
					</View>
					<ScrollView style={{ maxHeight: height * 0.7 }}>
						<Stack spacing={spacing.lg}>
							<Flair size="medium">
								<View style={styles.iconBox}>
									<LockOpenIcon color={colors.content_on_fill} size={44} />
								</View>
							</Flair>
							<CMSContent value={content} />
							<Button
								title={ctaText}
								icon={LockOpenIcon}
								onPress={onCtaPress}
							/>
						</Stack>
					</ScrollView>
				</Stack>
			</BackgroundView>
		</BackgroundView>
	);
};

const stylesheet = createStyleSheet(({ spacing, radius }) => ({
	root: {
		justifyContent: 'center',
		alignItems: Platform.select({
			web: 'center',
			default: undefined,
		}),
		flex: 1,
	},
	dialog: {
		maxWidth: Platform.select({
			web: SCREEN_MAX_WIDTH_SM,
			default: undefined,
		}),
		borderRadius: radius.sm,
		padding: spacing.md,
		margin: spacing.md,
		gap: spacing.md,
		overflow: 'hidden',
	},
	header: {
		flexDirection: 'row',
		alignItems: 'center',
	},
	headerTitleText: {
		flex: 1,
		marginTop: 2,
	},
	containedActionContainer: {
		marginTop: 2,
	},
	iconBox: {
		alignItems: 'center',
		justifyContent: 'center',
		flex: 1,
	},
}));

export default FreeUserFirstCourseCompleteView;
