import { ReactNode } from 'react';
import { StyleSheet } from 'react-native';

import BackgroundView from './BackgroundView';

type Props = {
	children: ReactNode;
};

const MainView = ({ children }: Props) => {
	return (
		<BackgroundView background="background_primary" style={styles.root}>
			{children}
		</BackgroundView>
	);
};
const styles = StyleSheet.create({
	root: {
		flex: 1,
	},
});

export default MainView;
