import { themeColors, UITheme } from '@wearemojo/ui-constants';
import ExclamationIcon from 'react-native-heroicons/outline/ExclamationIcon';
import WifiIcon from 'react-native-heroicons/outline/WifiIcon';

export enum ErrorType {
	generic = 'generic',
	connection = 'connection',
}

const errorFlairColors = {
	[UITheme.dark]: {
		[ErrorType.generic]: themeColors[UITheme.dark].fill_warning,
		[ErrorType.connection]: themeColors[UITheme.dark].fill_highlight,
	},
};

const errorIconColors = {
	[UITheme.dark]: {
		[ErrorType.generic]: themeColors[UITheme.dark].content_on_dark,
		[ErrorType.connection]: themeColors[UITheme.dark].content_on_fill,
	},
};

export const getErrorFlairColor = (
	type: keyof typeof ErrorType,
	theme: UITheme,
) => {
	return errorFlairColors[theme][type];
};

export const getErrorIconColor = (
	type: keyof typeof ErrorType,
	theme: UITheme,
) => {
	return errorIconColors[theme][type];
};

export const errorIcons = {
	[ErrorType.generic]: ExclamationIcon,
	[ErrorType.connection]: WifiIcon,
};
